import { APIv4 } from 'src/libs/axios';
import { OptionFull } from '../../../../components/MainPage/_shared/Options';

export type CommonViewProduct = {
  id: number;
  name: string;
  position: number;
  isActive: boolean;
  variants: {
    id: number;
    isActive: boolean;
    options: OptionFull[];
  }[];
};

export const fetchCommonViewProducts = (): Promise<CommonViewProduct[]> =>
  APIv4.get(`/products/common-view`).then((response) => response.data);
