import { cloneDeep } from 'lodash';
import { CommonViewProduct } from '../../../../pages/catalog/MenuPage/services';
import { BaseProductSet } from 'src/pages/promotions/PromotionsPage/services';
import { OptionFull } from '../Options';

export const getProductSets = (
  products: CommonViewProduct[],
  productSets: Omit<BaseProductSet, 'isFree'>[]
) => {
  return productSets.map((set) => {
    const { products: variants, ...rest } = set;

    const mappedProducts = variants.reduce(
      (acc, item) => {
        const product = products.find(({ name }) => name === item.name);
        if (!product) return acc;

        if (!acc[item.name]) {
          const currentProduct = cloneDeep(product);
          currentProduct.isActive = false;
          currentProduct.variants = [];
          acc[item.name] = currentProduct;
        }

        const variant = product.variants.find(
          (variant) => variant.id === item.id
        );

        if (!variant) return acc;
        acc[item.name].variants.push(variant);

        return acc;
      },
      {} as Record<
        string,
        {
          name: string;
          isActive: boolean;
          variants: {
            id: number;
            isActive: boolean;
            options: OptionFull[];
          }[];
        }
      >
    );

    return {
      ...rest,
      localId: String(rest.id),
      products: Object.values(mappedProducts).map((item) => ({
        ...item,
        isActive: item.variants.some(({ isActive }) => isActive),
      })),
    };
  });
};
