import React from 'react';
import { Container, ContainerProps } from '@mui/material';

type PageContainerProps = ContainerProps & { withOverflow?: boolean };

export const PageContainer = (props: PageContainerProps) => {
  const { withOverflow, children, sx, ...rest } = props;

  return (
    <Container
      className="pageContent"
      sx={{
        ...(!withOverflow && {
          flex: '1 0 auto',
          '& .pageContent': {
            height: 0,
            flex: '1 0 auto',
          },
        }),
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        ...(!rest.disableGutters && { py: 2 }),
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Container>
  );
};
