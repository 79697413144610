import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';

const RedirectRoute = () => {
  const { user, auth } = useAuth();
  const location = useLocation();
  const to = user ? '/main' : '/auth';

  return <Navigate to={to} state={{ from: location }} replace />;
};

export default RedirectRoute;
