import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { Circle } from '@mui/icons-material';

type NumberButtonProps = ButtonProps & { isBusy: boolean; isOwn: boolean };

export const NumberButton = (props: NumberButtonProps) => {
  const { isBusy, isOwn, children, ...rest } = props;
  const color = isBusy
    ? 'secondary.extraLight'
    : isOwn
    ? 'white'
    : 'primary.main';

  return (
    <Button
      color="primary"
      variant="text"
      sx={{
        px: 2,
        py: 1.5,
        gap: 1,
        fontSize: 16,
        fontWeight: '400',
        justifyContent: 'left',
        color: 'secondary.main',
        [`&.MuiButton-textPrimary:hover`]: {
          color: 'secondary.main',
        },
        borderRadius: 0,
        ...(isOwn && {
          [`&.Mui-disabled`]: { color: 'secondary.dark' },
          backgroundColor: 'primary.light',
        }),
        ...(isBusy && {
          [`&.Mui-disabled`]: { color: 'secondary.extraLight' },
        }),
      }}
      fullWidth
      {...rest}
    >
      <Circle sx={{ fontSize: 12, color }} />
      {children}
    </Button>
  );
};
