import React, { memo } from 'react';
import { CustomTooltip } from 'src/uikit/Tooltip';
import { OrderStatusShort } from '../../services/fetchOrders';
import { IconSize, OrderStatus } from './constants';

type OrderStatusIconProps = {
  status: OrderStatusShort;
  size?: IconSize;
  hideTooltip?: boolean;
  isOpen?: boolean;
  isWarning?: boolean;
  overlay?: boolean;
};

const OrderStatusIcon = (props: OrderStatusIconProps) => {
  const { status, size, hideTooltip, isOpen, isWarning, overlay } = props;
  const { icon: Icon, title, fill } = OrderStatus[status.statusCode];

  return (
    <CustomTooltip
      title={
        !hideTooltip &&
        title + (status.description ? `, ${status.description}` : '')
      }
    >
      <Icon
        style={{
          width: `${size || IconSize.MEDIUM}px`,
          height: `${size || IconSize.MEDIUM}px`,
          fill: isOpen ? '#1CD1D1' : isWarning ? '#ef5243' : fill,
          ...(overlay && {
            background: 'white',
            borderRadius: '50%',
            border: '2px solid white',
          }),
        }}
      />
    </CustomTooltip>
  );
};

export const MemoizedOrderStatusIcon = memo(OrderStatusIcon);
export default OrderStatusIcon;
