// TODO: consider to fetch values from database
const Role = {
  SUPERADMIN: { label: 'SuperAdmin', id: 1, name: 'СуперАдмин' },
  ADMIN: { label: 'Admin', id: 2, name: 'Админ' },
  OPERATOR: { label: 'Operator', id: 3, name: 'Оператор' },
  CASHIER: { label: 'Cashier', id: 4, name: 'Кассир' },
  COURIER: { label: 'Courier', id: 5, name: 'Курьер' },
  CHIEFCASHIER: { label: 'ChiefCashier', id: 6, name: 'Старший Кассир' },
};

const GRAM = 'г';
const ROUBLE = '₽';
const CALORIES = 'ккал';

const all = [
  Role.SUPERADMIN,
  Role.ADMIN,
  Role.OPERATOR,
  Role.CASHIER,
  Role.COURIER,
  Role.CHIEFCASHIER,
].map(({ label }) => label);

const cashierAndOperatorToSuperAdmin = [
  Role.SUPERADMIN,
  Role.ADMIN,
  Role.OPERATOR,
  Role.CHIEFCASHIER,
  Role.CASHIER,
].map(({ label }) => label);

const operatorToSuperAdmin = [Role.SUPERADMIN, Role.ADMIN, Role.OPERATOR].map(
  ({ label }) => label
);

const cashierToSuperAdmin = [
  Role.SUPERADMIN,
  Role.ADMIN,
  Role.CHIEFCASHIER,
  Role.CASHIER,
].map(({ label }) => label);

const adminToSuperAdmin = [Role.SUPERADMIN, Role.ADMIN].map(
  ({ label }) => label
);

const roleSet = {
  all,
  cashierAndOperatorToSuperAdmin,
  operatorToSuperAdmin,
  cashierToSuperAdmin,
  adminToSuperAdmin,
  superAdmin: [Role.SUPERADMIN.label],
};

// TODO: consider to fetch values from database
const cities = [{ id: 1, name: 'Петрозаводск' }];

export { Role, roleSet, cities, GRAM, ROUBLE, CALORIES };
