// mask: +7*999*999*99*99
export function maskPhoneValue(phone: string | null) {
  if (!phone) return '';

  return [
    phone.slice(0, 2),
    ' ',
    phone.slice(2, 5),
    ' ',
    phone.slice(5, 8),
    ' ',
    phone.slice(8, 10),
    ' ',
    phone.slice(10, 12),
  ].join('');
}

// removes " " from string
export const unmaskPhoneValue = (phone: string) => {
  if (!phone) return null;

  return phone.replace(/[\ ]/g, '');
};

export const isPhoneNotValid = (phone: string) =>
  phone
    .toString()
    .split('')
    .filter((el) => !isNaN(+el)).length !== 11;

export const getClearPhoneNumber = (phone: string) =>
  phone.toString().replaceAll('-', '').replaceAll('(', '').replaceAll(')', '');

export const getPhoneNumberWithoutUnderscore = (phone: string) =>
  phone.toString().replaceAll('_', '');

export const getFormattedPhoneNumber = (phone: string) => {
  if (!phone) return '';

  return (
    phone.slice(0, 2) +
    ' (' +
    phone.slice(2, 5) +
    ') ' +
    phone.slice(5, 8) +
    '-' +
    phone.slice(8, 10) +
    '-' +
    phone.slice(10, 12)
  );
};
