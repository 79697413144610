import React, { FC, ReactNode, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { SidebarContext } from './contexts/SidebarContext';
import { OrdersContext } from './contexts/OrdersContext';
import { UserCookie } from '../_shared/types';
import cookies from 'src/libs/cookies';
import { roleSet } from '../../constants/role';
import Howler from './Howler';
import { DrawerSize } from './Sidebar';
import { MangoContext } from '../_shared/MangoContext';
import { useLocation } from 'react-router';

interface ContentWrapperProps {
  children: ReactNode;
}

const Main = styled(Box)(
  ({ theme }) => `
  background-color: ${theme.palette.light.extraLight};
  flex-grow: 1;
  max-width: '100%';
  `
);

const ContentWrapper: FC<ContentWrapperProps> = ({
  children,
}: ContentWrapperProps) => {
  const location = useLocation();
  const isCashierPage = location.pathname.includes('cashier');

  const theme = useTheme();
  const { isCall } = useContext(MangoContext);
  const { sidebarWidth } = useContext(SidebarContext);
  const { updateOrders } = useContext(OrdersContext);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const contentPadding = isExtraSmallScreen
    ? 0
    : isSmallScreen
    ? DrawerSize.INACTIVE
    : sidebarWidth;

  const user: UserCookie | undefined = cookies.get('user');

  const HowlerMemoed = useMemo(
    () => (
      <>
        {user?.role && roleSet.operatorToSuperAdmin.includes(user.role) ? (
          <>
            <Howler updateOrders={updateOrders} />
          </>
        ) : (
          <></>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  return (
    <Main
      sx={{
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...(!isCashierPage && { pl: `${contentPadding}px` }),
        ...(isCall && { overflow: 'auto', pb: { xs: '150px', md: '100px' } }),
      }}
    >
      {!isCashierPage && <Toolbar />}
      {HowlerMemoed}
      {children}
    </Main>
  );
};

export default ContentWrapper;
