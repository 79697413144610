import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';

export const BreadcrumbLabel = (props: { title: string; arrow?: boolean }) => {
  const { title, arrow } = props;

  return (
    <>
      {arrow && <ArrowBack sx={{ width: '1rem' }} />}
      {title}
    </>
  );
};

export const Breadcrumb = ({ children, ...props }: TypographyProps) => {
  return (
    <Typography
      component="span"
      display="flex"
      alignItems="center"
      gap={1}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const getCrumb =
  (title: string, arrow = false) =>
  () =>
    <BreadcrumbLabel title={title} arrow={arrow} />;
