import React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  ButtonProps,
  Divider,
  DividerProps,
  IconButton,
} from '@mui/material';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    color: 'currentColor',
  },
  '& .MuiDialogTitle-root': {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  '& .MuiDialogContent-root': {
    padding: 0,
    // padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  '& .MuiDialogActions-root': {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
}));

export interface CustomDialogTitleProps {
  id?: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (
  props: CustomDialogTitleProps & Partial<DialogTitleProps>
) => {
  const { children, onClose, sx, ...other } = props;

  return (
    <DialogTitle
      sx={{
        color: 'primary.contrastText',
        backgroundColor: 'primary.main',
        wordBreak: 'break-word',
        pr: '54px!important',
        ...sx,
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 12,
            right: 12,
            color: 'inherit',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const CustomDivider = ({ sx, ...props }: DividerProps) => {
  return <Divider {...props} sx={{ borderColor: 'light.main', ...sx }} />;
};

const Decline = ({ children, sx, ...props }: ButtonProps) => {
  return (
    <Button
      size="small"
      color="light"
      variant="contained"
      disableElevation
      data-testid="decline-button"
      {...props}
      sx={{
        textTransform: 'uppercase',
        ...(!props.color && { color: 'secondary.main' }),
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

const Confirm = ({ children, sx, ...props }: ButtonProps) => {
  return (
    <Button
      size="small"
      color="primary"
      variant="contained"
      disableElevation
      data-testid="confirm-button"
      {...props}
      sx={{ textTransform: 'uppercase', ...sx }}
    >
      {children}
    </Button>
  );
};

export { CustomDialog, CustomDialogTitle, Confirm, Decline, CustomDivider };
export { ModalDialog } from './ModalDialog';
