import { Box } from '@mui/material';
import React, {
  ComponentType,
  PropsWithChildren,
  ReactNode,
  Suspense,
} from 'react';
import { Await, useAsyncError, useLoaderData } from 'react-router';
import { getErrorMsg } from 'src/utils/getErrorMsg';
import { Loader } from './Loader';

type SuspenseContainerProps = {
  fallback?: ReactNode;
  errorElement?: ReactNode;
};

const AwaitedError = () => {
  const error: any = useAsyncError();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%' }}
    >
      <p style={{ color: 'red' }}>
        Ошибка:
        <br />
        {getErrorMsg(error)}
      </p>
    </Box>
  );
};

export const SuspenseContainer = (
  props: PropsWithChildren<SuspenseContainerProps>
) => {
  const { fallback, errorElement, children } = props;
  const { data }: any = useLoaderData();

  return (
    <Suspense fallback={fallback || <Loader />}>
      <Await resolve={data} errorElement={errorElement || <AwaitedError />}>
        {children}
      </Await>
    </Suspense>
  );
};

export const withSuspense = (Component: ComponentType<any>) => {
  return (props: any) => (
    <SuspenseContainer>
      <Component {...props} />
    </SuspenseContainer>
  );
};
