import {
  KladrObject,
  KladrObjectType,
} from '../MainPage/StreetsPage/_shared/types';
import moment, { Moment } from 'moment/moment';
import { ASAP } from '../../constants/order';

enum UserAgent {
  Chrome,
  Safari,
  Firefox,
  Unknown,
}

const getAgent = () => {
  const chromeAgent = navigator.userAgent.indexOf('Chrome') > -1;
  const safariAgent = navigator.userAgent.indexOf('Safari') > -1;
  const firefoxAgent = navigator.userAgent.indexOf('Firefox') > -1;

  switch (true) {
    case chromeAgent && safariAgent:
      return UserAgent.Chrome;
    case safariAgent:
      return UserAgent.Safari;
    case firefoxAgent:
      return UserAgent.Firefox;
    default:
      return UserAgent.Unknown;
  }
};

const getOffset = () => {
  switch (getAgent()) {
    case UserAgent.Safari:
      return 0;
    case UserAgent.Chrome:
      return 3;
    case UserAgent.Firefox:
      return 3;
    default:
      return 0;
  }
};

export const renderDate = (
  date: string | Date,
  forPromotionSchedule?: boolean,
  withoutOffset?: boolean
) => {
  const newDate = new Date(date);
  const hours =
    newDate.getHours() +
    (forPromotionSchedule || withoutOffset ? 0 : getOffset());

  const minutes = newDate.getMinutes();
  const h = hours < 10 ? `0${hours}` : '' + hours;
  const m = minutes < 10 ? `0${minutes}` : '' + minutes;

  return forPromotionSchedule
    ? `${h}:${m}:00`
    : `${new Date(date).toLocaleDateString('ru-RU')} ${h}:${m}`;
};

export const getMonthNameByNumber = (number: number) => {
  let result = '0';
  switch (number) {
    case 0:
      result = 'янв';
      break;
    case 1:
      result = 'фев';
      break;
    case 2:
      result = 'мар';
      break;
    case 3:
      result = 'апр';
      break;
    case 4:
      result = 'мая';
      break;
    case 5:
      result = 'июн';
      break;
    case 6:
      result = 'июл';
      break;
    case 7:
      result = 'авг';
      break;
    case 8:
      result = 'сен';
      break;
    case 9:
      result = 'окт';
      break;
    case 10:
      result = 'ноя';
      break;
    case 11:
      result = 'дек';
      break;
  }
  return result;
};

export const getDayKey = (momentDate: Moment) => {
  const day = momentDate.date();
  const month = getMonthNameByNumber(momentDate.month());

  return `${day} ${month}`;
};

export const getIntFromString = (string: string, canBeEmpty?: boolean) => {
  return !isNaN(+string) && !string.includes('-') && string !== ''
    ? parseInt(string)
    : 0;
};

export const getZeroOrString = (string: string, canBeEmpty?: boolean) => {
  if (canBeEmpty && string === '') return '';

  return !isNaN(+string) ? string : 0;
};

export const copyObject = (object: {}) => JSON.parse(JSON.stringify(object));

export const downloadFile = (data: any, filename: string, type: string) => {
  const file = new Blob([data], { type: type });
  if ((window.navigator as any).msSaveOrOpenBlob)
    // IE10+
    (window.navigator as any).msSaveOrOpenBlob(file, filename);
  else {
    // Others
    const a = document.createElement('a'),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

export const getStreetsData = (
  data: KladrObject[],
  types: KladrObjectType[]
) => {
  let rowsData: {
    district: KladrObject | undefined;
    streets: KladrObject[];
  }[] = [];
  const districtTypeId =
    types.find((el) => el.nameRu.toLowerCase() === 'район')?.id || 2;
  for (const dataEl of data) {
    if (dataEl.kladrObjectTypeId === districtTypeId)
      rowsData = [
        ...rowsData,
        { district: dataEl, streets: dataEl.childrenList },
      ];
    else rowsData = [...rowsData, { district: undefined, streets: [dataEl] }];
  }
  return rowsData;
};

export const getPassedTime = (
  createdAt: string,
  format = 'DD.MM.YYYY HH:mm'
) => {
  const now = moment();
  const createdDate = moment(createdAt);

  const days = moment()
    .startOf('day')
    .diff(moment(createdAt).startOf('day'), 'days');
  const minutes = now.diff(createdDate, 'minutes');
  const hours = now.diff(createdDate, 'hours');

  if (minutes <= 1) return 'только что';
  if (hours < 1) return `${minutes} мин назад`;

  const time = createdDate.format('HH:mm');

  if (days === 0) return `сегодня в ${time}`;
  if (days === 1) return `вчера в ${time}`;

  return createdDate.format(format);
};

const adaptReadyTime = (time: string) => {
  const date = moment(time);
  return `${date.format('DD.MM')} к ${date.format('HH:mm')}`;
};

export const getTimeLeft = (time: string) => {
  const hour = 1000 * 60 * 60;
  if (Math.abs(moment().diff(moment(time))) > hour) {
    return adaptReadyTime(time);
  }
  const now = moment();
  const orderTime = moment(time);
  const diffMS = orderTime.diff(now);
  const m = moment.duration(diffMS);
  if (diffMS > 0) {
    const minutes = Math.floor(m.asMinutes());
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes - hours * 60;

    return `через ${hours}:${
      minutesLeft < 10 ? '0' + String(minutesLeft) : minutesLeft
    }`;
  } else {
    const minutes = Math.floor(m.asMinutes());
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes - hours * 60;

    return `${Math.abs(hours)}:${
      minutesLeft < 10 ? '0' + String(minutesLeft) : minutesLeft
    } назад`;
  }
};

export const getDeliveryTime = (
  readyTime: string,
  waitingTime: string | null
) => {
  const date = moment(readyTime).format('DD.MM');
  const isDateInTime = waitingTime?.includes(date);

  return waitingTime
    ? (readyTime && !isDateInTime ? date + ' ' : '') + waitingTime
    : readyTime
    ? getTimeLeft(readyTime)
    : ASAP;
};

export const getUserName = (
  user:
    | {
        lastName: string;
        firstName: string;
        patronymic: string;
      }
    | undefined,
  showFull = false
) => {
  const lastName = user?.lastName;
  const firstName = user?.firstName;
  const patronymic = user?.patronymic;

  if (showFull) {
    return [lastName, firstName, patronymic].filter(Boolean).join(' ');
  }

  return `${lastName} ${firstName?.slice(0, 1)}.${patronymic?.slice(0, 1)}`;
};

export const getClientName = (
  user:
    | {
        lastName: string | null;
        firstName: string | null;
        patronymic: string | null;
        phoneNumber: string;
      }
    | undefined
) => {
  if (!user) return '';

  const { lastName, firstName, patronymic, phoneNumber } = user;
  const name = [lastName, firstName, patronymic].filter(Boolean).join(' ');

  if (name.length > 0) {
    const phone = phoneNumber ? ` (${phoneNumber})` : '';
    return name + phone;
  } else {
    return phoneNumber;
  }
};

export const timeMask = 'YYYY-MM-DDTHH:mm:ss';

export const stringToDate = (time: string | null) => {
  if (!time) return '';
  return moment(time, timeMask).toDate();
};

export const dateToString = (
  time: Date | string | null,
  mask: string = timeMask,
  keepError = false
) => {
  if (!time) return '';
  const formatted = moment(time).format(mask);
  if (formatted === 'Invalid date') return keepError ? 'Invalid date' : '';
  return formatted;
};
