import React, { lazy } from 'react';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import ProtectedRoute from 'src/pages/utility/ProtectedRoute';
import { getCrumb } from 'src/components/MainPage/_shared/Breadcrumbs/Breadcrumb';
import { SuspenseContainer } from 'src/components/MainPage/_shared/page';
import { roleSet } from 'src/constants/role';
import { queryClient } from '../queryClient';
import type { AppRoute } from '../types';

const MenuPage = lazy(() => import('src/pages/catalog/MenuPage'));
const ProductsPage = lazy(() => import('src/pages/catalog/ProductsPage'));
const ProductPage = lazy(() => import('src/pages/catalog/ProductPage'));
const CategoriesPage = lazy(() => import('src/pages/catalog/CategoriesPage'));
const CategoryPage = lazy(() => import('src/pages/catalog/CategoryPage'));
const IngredientsPage = lazy(() => import('src/pages/catalog/IngredientsPage'));
const IngredientPage = lazy(() => import('src/pages/catalog/IngredientPage'));
const OptionsPage = lazy(() => import('src/pages/catalog/OptionsPage'));
const OptionPage = lazy(() => import('src/pages/catalog/OptionPage'));
const FiltersPage = lazy(() => import('src/pages/catalog/FiltersPage'));
const FilterPage = lazy(() => import('src/pages/catalog/FilterPage'));

export const catalogRoutes: AppRoute[] = [
  {
    element: <ProtectedRoute roles={roleSet.adminToSuperAdmin} />,
    handle: {
      label: 'Каталог',
      icon: <LocalPizzaIcon />,
      roles: roleSet.adminToSuperAdmin,
    },
    children: [
      {
        path: `admin`,
        element: <MenuPage />,
        handle: { label: 'Меню', roles: roleSet.adminToSuperAdmin },
      },
      {
        path: `products`,
        handle: {
          crumb: getCrumb('Товары', true),
        },
        children: [
          {
            index: true,
            element: <ProductsPage />,
            handle: {
              label: 'Товары',
              roles: roleSet.adminToSuperAdmin,
            },
          },
          {
            path: `:id`,
            element: (
              <SuspenseContainer>
                <ProductPage />
              </SuspenseContainer>
            ),
            async lazy() {
              const { productLoader } = await import(
                'src/pages/catalog/ProductPage/productLoader'
              );
              return {
                loader: productLoader(queryClient),
              };
            },
          },
        ],
      },
      {
        path: `categories`,
        handle: {
          crumb: getCrumb('Категории', true),
        },
        children: [
          {
            index: true,
            element: <CategoriesPage />,
            handle: {
              label: 'Категории',
              roles: roleSet.adminToSuperAdmin,
            },
          },
          {
            path: `:id`,
            element: (
              <SuspenseContainer>
                <CategoryPage />
              </SuspenseContainer>
            ),
            async lazy() {
              const { categoryLoader } = await import(
                'src/pages/catalog/CategoryPage/categoryLoader'
              );
              return {
                loader: categoryLoader,
              };
            },
          },
        ],
      },
      {
        path: `ingredients`,
        handle: {
          crumb: getCrumb('Ингредиенты', true),
        },
        children: [
          {
            index: true,
            element: <IngredientsPage />,
            handle: {
              label: 'Ингредиенты',
              roles: roleSet.adminToSuperAdmin,
            },
          },
          {
            path: `:id`,
            element: (
              <SuspenseContainer>
                <IngredientPage />
              </SuspenseContainer>
            ),
            async lazy() {
              const { ingredientLoader } = await import(
                'src/pages/catalog/IngredientPage/ingredientLoader'
              );
              return {
                loader: ingredientLoader(queryClient),
              };
            },
          },
        ],
      },
      {
        path: `options`,
        handle: {
          crumb: getCrumb('Опции', true),
        },
        children: [
          {
            index: true,
            element: <OptionsPage />,
            handle: {
              label: 'Опции',
              roles: roleSet.adminToSuperAdmin,
            },
          },
          {
            path: `:id`,
            element: (
              <SuspenseContainer>
                <OptionPage />
              </SuspenseContainer>
            ),
            async lazy() {
              const { optionLoader } = await import(
                'src/pages/catalog/OptionPage/optionLoader'
              );
              return {
                loader: optionLoader,
              };
            },
          },
        ],
      },
      {
        path: `filters`,
        handle: {
          crumb: getCrumb('Фильтры', true),
        },
        children: [
          {
            index: true,
            element: <FiltersPage />,
            handle: {
              label: 'Фильтры',
              roles: roleSet.adminToSuperAdmin,
            },
          },
          {
            path: `:id`,
            element: (
              <SuspenseContainer>
                <FilterPage />
              </SuspenseContainer>
            ),
            async lazy() {
              const { filterLoader } = await import(
                'src/pages/catalog/FilterPage/filterLoader'
              );
              return {
                loader: filterLoader,
              };
            },
          },
        ],
      },
    ],
  },
];
