import React, { lazy } from 'react';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ProtectedRoute from 'src/pages/utility/ProtectedRoute';
import { roleSet } from 'src/constants/role';
import type { AppRoute } from '../types';

const CashierMenuPage = lazy(
  () => import('src/pages/management/CashierPage/CashierMenuPage')
);
const CashierPage = lazy(() => import('src/pages/management/CashierPage'));

export const cashRoutes: AppRoute[] = [
  {
    path: `cash`,
    element: <ProtectedRoute roles={roleSet.cashierAndOperatorToSuperAdmin} />,
    handle: {
      label: 'Касса',
      icon: <PointOfSaleIcon />,
      roles: roleSet.cashierAndOperatorToSuperAdmin,
      keepPlain: true,
    },
    children: [
      { index: true, element: <CashierMenuPage /> },
      {
        path: `promocode`,
        element: <CashierPage />,
      },
    ],
  },
];
