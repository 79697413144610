import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AuthCookie, UserCookie } from 'src/components/_shared/types';
import cookies from 'src/libs/cookies';

type AuthContext = {
  isAuth: boolean;
  auth: AuthCookie | undefined;
  user: UserCookie | undefined;
};

export const AuthContext = createContext<AuthContext>({} as AuthContext);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [, setChange] = useState<any>();
  useEffect(() => {
    if (setChange) cookies.addChangeListener((data) => setChange(data));
  }, [setChange]);
  // const [auth, setAuth] = useState<AuthContext>({} as AuthContext);
  // const [isAuth, setIsAuth] = useState(false);

  // TODO: сделать нормальную авторизацию + контекст
  const auth: AuthCookie | undefined = cookies.get('auth');
  const user: UserCookie | undefined = cookies.get('user');
  const isAuth = Boolean(auth && user);

  return (
    <AuthContext.Provider value={{ isAuth, user, auth }}>
      {children}
    </AuthContext.Provider>
  );
};
