import React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const CustomTooltip = ({ children, ...props }: TooltipProps) => {
  return (
    <Tooltip
      placement="top"
      arrow
      sx={{
        position: 'absolute',
        top: '-10px',
        right: '-9px',
      }}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'secondary.extraLight',
          },
        },
        arrow: {
          sx: {
            color: 'secondary.extraLight',
          },
        },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

const InfoTooltip = ({ title }: { title: string }) => {
  return (
    <CustomTooltip title={title} placement="right">
      <ErrorIcon color="primary" sx={{ fontSize: 16 }} />
    </CustomTooltip>
  );
};

export { CustomTooltip, InfoTooltip };
