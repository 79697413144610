import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { ThemeProvider } from '@mui/material';
import getCustomTheme from 'src/_styled/theme';
import { useAppSettings } from 'src/hooks/useAppSettings';
import { useDocumentTitle, useFavicon } from '@siberiacancode/reactuse';

export const AppSettingsAndThemeProvider = ({
  children,
}: PropsWithChildren) => {
  const { data } = useAppSettings();
  const [, setTitle] = useDocumentTitle();
  const { set } = useFavicon();

  useEffect(() => {
    if (data?.name) set(`/assets/images/${data.name}/favicon.ico`);
  }, [data?.name, set]);

  useEffect(() => {
    if (data?.title) setTitle(`${data.title}: Админка`);
  }, [data?.title, setTitle]);

  const customTheme = useMemo(
    () => getCustomTheme(data?.primaryColor),
    [data?.primaryColor]
  );

  return <ThemeProvider theme={customTheme}>{children}</ThemeProvider>;
};
