import React, { lazy } from 'react';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ProtectedRoute from 'src/pages/utility/ProtectedRoute';
import { getCrumb } from 'src/components/MainPage/_shared/Breadcrumbs/Breadcrumb';
import { SuspenseContainer } from 'src/components/MainPage/_shared/page';
import { roleSet } from 'src/constants/role';
import { queryClient } from '../queryClient';
import type { AppRoute } from '../types';
import { promotionLoader } from 'src/pages/promotions/PromotionPage/promotionLoader';
import { promocodeLoader } from 'src/pages/promotions/PromoCodePage/promocodeLoader';

const BannersPage = lazy(() => import('src/pages/promotions/BannersPage'));
const BannerPage = lazy(() => import('src/pages/promotions/BannerPage'));
const PromotionsPage = lazy(
  () => import('src/pages/promotions/PromotionsPage')
);
const PromotionPage = lazy(() => import('src/pages/promotions/PromotionPage'));
const PromotionSchedulePage = lazy(
  () => import('src/pages/promotions/PromotionSchedulePage')
);
const PromoCodesPage = lazy(
  () => import('src/pages/promotions/PromoCodesPage')
);
const PromoCodePage = lazy(() => import('src/pages/promotions/PromoCodePage'));

export const promotionsRoutes: AppRoute[] = [
  {
    element: <ProtectedRoute roles={roleSet.cashierAndOperatorToSuperAdmin} />,
    handle: {
      label: 'Промоакции',
      icon: <LoyaltyIcon />,
      roles: roleSet.cashierAndOperatorToSuperAdmin,
    },
    children: [
      {
        element: <ProtectedRoute roles={roleSet.adminToSuperAdmin} />,
        children: [
          {
            path: `banners`,
            handle: {
              crumb: getCrumb('Баннеры', true),
            },
            children: [
              {
                index: true,
                element: <BannersPage />,
                handle: {
                  label: 'Баннеры',
                  roles: roleSet.adminToSuperAdmin,
                },
              },
              {
                path: `:id`,
                element: (
                  <SuspenseContainer>
                    <BannerPage />
                  </SuspenseContainer>
                ),
                async lazy() {
                  const { bannerLoader } = await import(
                    'src/pages/promotions/BannerPage/bannerLoader'
                  );
                  return {
                    loader: bannerLoader,
                  };
                },
              },
            ],
          },
          {
            path: `promotions`,
            handle: {
              crumb: getCrumb('Акции', true),
            },
            children: [
              {
                index: true,
                element: <PromotionsPage />,
                handle: {
                  label: 'Акции',
                  roles: roleSet.adminToSuperAdmin,
                },
              },
              {
                path: `:id`,
                element: <PromotionPage />,
                loader: promotionLoader,
              },
            ],
          },
          {
            path: `promotionSchedule`,
            element: <PromotionSchedulePage />,
            handle: {
              label: 'График работы акций',
              roles: roleSet.adminToSuperAdmin,
            },
          },
        ],
      },
      {
        path: `promocodes`,
        handle: {
          crumb: getCrumb('Промокоды', true),
        },
        children: [
          {
            index: true,
            element: <PromoCodesPage />,
            handle: {
              label: 'Промокоды',
              roles: roleSet.cashierAndOperatorToSuperAdmin,
            },
          },
          {
            path: `:id`,
            element: <PromoCodePage />,
            loader: promocodeLoader(queryClient),
          },
        ],
      },
    ],
  },
];
