import React, { FC, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthCookie, UserCookie } from 'src/components/_shared/types';
import { APIv4 as API } from 'src/libs/axios';
import cookies from 'src/libs/cookies';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ProgressBar } from 'src/components/MainPage/_shared/ProgressBar';
import { alertError } from 'src/libs/toastify';

const useStyles = makeStyles(() => ({
  requiredWarning: {
    color: '#f44336',
    margin: '4px 0 0 0',
    fontSize: '0.75rem',
    letterSpacing: '0.03333em',
    lineHeight: '1.66',
  },
  page: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& form': {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 'calc(400px - 16px * 2)',
      flexGrow: 1,
      '& > *:last-child': {
        margin: '16px 0',
      },
    },
  },
}));

const AuthPage: FC = () => {
  const location = useLocation();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<any>(null);
  const [modal, setModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    const auth: AuthCookie | undefined = cookies.get('auth');
    const user: UserCookie | undefined = cookies.get('user');

    if (auth?.accessToken && user?.role) setSuccess(true);
  }, []);

  const classes = useStyles();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setProgress(true);
    API.post(`/auth/login`, {
      userName: login,
      password: password,
    })
      .then(({ data }) => {
        const now = new Date();
        now.setTime(now.getTime() + 30 * 24 * 60 * 60 * 1000);
        cookies.set(
          'auth',
          {
            accessToken: data.token,
            refreshToken: data.refreshToken,
            userName: login,
          },
          { path: '/', expires: now, sameSite: 'lax' }
        );

        API.get(`/auth/me`)
          .then(({ data }) => {
            const expires = new Date();
            expires.setFullYear(expires.getFullYear() + 10);

            cookies.set('user', data, {
              path: '/',
              expires: expires,
              sameSite: 'lax',
            });

            setSuccess(true);
          })
          .catch((error) => {
            alertError(error, 'Ошибка загрузки');
          });
      })
      // .catch((error) => openDialog(error))
      .catch((error) => setError(error))
      .finally(() => setProgress(false));
  };

  // const openDialog = (error: any) => {
  //   setError(error);
  //   setModal(true);
  // };
  const closeDialog = () => {
    setModal(false);
  };

  const errors = {
    login: login === '',
    password: password === '',
  };

  const [blur, setBlur] = useState({
    login: false,
    password: false,
  });

  useEffect(() => {
    setError('');
  }, [login, password]);

  return success ? (
    <Navigate to={location.state?.from?.pathname || '/'} replace />
  ) : (
    <div className={classes.page}>
      <form onSubmit={onSubmit}>
        <TextField
          value={login}
          label="Логин"
          type="text"
          autoComplete="off"
          margin="dense"
          InputLabelProps={{ shrink: true }}
          onChange={(e: any) => setLogin(e.target.value)}
          onBlur={() => setBlur({ ...blur, login: true })}
          error={errors.login && blur.login}
          helperText={errors.login && blur.login ? 'Обязательное поле' : ''}
          data-testid="login_field"
        />
        <TextField
          value={password}
          label="Пароль"
          type="password"
          autoComplete="off"
          margin="dense"
          InputLabelProps={{ shrink: true }}
          onChange={(e: any) => setPassword(e.target.value)}
          onBlur={() => setBlur({ ...blur, password: true })}
          error={errors.password && blur.password}
          helperText={
            errors.password && blur.password ? 'Обязательное поле' : ''
          }
          data-testid="password_field"
        />
        {error !== '' && (
          <p className={classes.requiredWarning}>Неверный логин или пароль</p>
        )}
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={errors.login || errors.password}
          data-testid="sign_in_button"
        >
          Вход
        </Button>
      </form>
      <Dialog
        open={modal}
        onClose={closeDialog}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle id="simple-dialog-title">Ошибка</DialogTitle>
        <DialogContent>
          <p>{JSON.stringify(error)}</p>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={closeDialog}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {progress && <ProgressBar bottom />}
    </div>
  );
};

export default AuthPage;
