import { APIv4 } from 'src/libs/axios';
import { alertError } from 'src/libs/toastify';
import { BaseProductSet, ProductSet } from '../PromotionsPage/services';
import { omit } from 'lodash';
import { Customer, Pagination } from 'src/components/_shared/types';

export const GENERAL_TYPE = 0;
export const PERSONAL_TYPE = 1;
export const GIFT_TYPE = 2;

export const PromoCodeTypes = {
  [GENERAL_TYPE]: { name: 'Общий', value: GENERAL_TYPE },
  [PERSONAL_TYPE]: { name: 'Личный', value: PERSONAL_TYPE },
  [GIFT_TYPE]: { name: 'Подарочный', value: GIFT_TYPE },
};

export type BasePromoCode = {
  id: number;
  name: string;
  count: number;
  type: keyof typeof PromoCodeTypes;
  lifetime: null | number;
  isActive: boolean;
  forApplications: boolean;
  forSite: boolean;
  forOfflineUse: boolean;
};

export type PromoCode = {
  id: number;
  name: string;
  type: keyof typeof PromoCodeTypes;
  value: string;
  lifetime: number | null;
  isActive: boolean;
  forSite: boolean;
  forApplications: boolean;
  forOfflineUse: boolean;
  forPickup: boolean;
  forDelivery: boolean;
  forDeliveryNoLimit: boolean;
  oneTimePerUser: boolean;
  forBirthday: boolean;
  forFirstOrder: boolean;
  minPrice: number | null;
  discount: number | null;
  conditions: BaseProductSet[];
  presents: BaseProductSet[];
};

export type PromoCodeValues = Omit<
  PromoCode,
  'id' | 'conditions' | 'presents'
> & {
  hasDiscount: boolean;
  hasMinPrice: boolean;
  conditions: ProductSet[];
  presents: ProductSet[];
};

export type PromoCodeValue = {
  customerId: number | null;
  startDate: string | null;
  valuesCount: number | null;
  commentary: string;
  isRus: boolean;
  isEng: boolean;
  isNum: boolean;
};

export type PromoCodeValueValues = Omit<PromoCodeValue, 'customerId'> & {
  customer: Customer | null;
  type: PromoCode['type'];
};

export type ValuesPromoCode = {
  id: number;
  value: string;
  customerPhone: string;
  commentary: string;
  startDate: string;
  expirationDate: string;
};
export const fetchPromoCodeValues = (params: { id: number; query: string }) =>
  APIv4.get<Pagination<ValuesPromoCode>>(
    `/promocodes/${params.id}/values?${params.query}`
  )
    .then(({ data }) => data)
    .catch((err) =>
      alertError(err, `Ошибка получения списка значений промокода`)
    );

type HistoryPromoCode = {
  promocodeValue: string;
  customerPhone: string;
  orderId: number | null;
  usageDateTime: string;
};

export const fetchPromoCodeHistory = (params: { id: number; query: string }) =>
  APIv4.get<Pagination<HistoryPromoCode>>(
    `/promocodes/${params.id}/history?${params.query}`
  )
    .then(({ data }) => data)
    .catch((err) =>
      alertError(err, `Ошибка получения истории использования промокода`)
    );

export const fetchPromoCodes = () =>
  APIv4.get<BasePromoCode[]>(`/promocodes`)
    .then(({ data }) => data.reverse())
    .catch((err) => alertError(err, `Ошибка получения списка промокодов`));

export const fetchPromoCode = ({ id }: { id: number }) =>
  APIv4.get<PromoCode>(`/promocodes/${id}`)
    .then(({ data }) => data)
    .catch((err) => alertError(err, `Ошибка получения промокода`));

const adaptPromoCode = (values: PromoCodeValues, isNew?: boolean) => {
  const { conditions, presents, hasDiscount, hasMinPrice, value, ...rest } =
    values;

  return {
    ...rest,
    value: values.type === GENERAL_TYPE && isNew ? value : undefined,
    lifetime: typeof values.lifetime !== 'number' ? null : values.lifetime,
    oneTimePerUser:
      values.type === GENERAL_TYPE ? values.oneTimePerUser : false,
    conditions: hasMinPrice
      ? []
      : conditions.map(({ products, ...rest }) => ({
          ...omit(rest, 'localId'),
          products: products.flatMap(({ variants }) =>
            variants.map(({ id }) => id)
          ),
        })),
    presents: hasDiscount
      ? []
      : presents.map(({ products, ...rest }) => ({
          ...omit(rest, 'localId', 'isActive'),
          products: products.flatMap(({ variants }) =>
            variants.map(({ id }) => id)
          ),
        })),
  };
};

export const addPromoCode = (data: PromoCodeValues) =>
  APIv4.post(`/promocodes`, adaptPromoCode(data, true)).then(({ data }) =>
    Boolean(data)
  );

export const updatePromoCode = (params: {
  id: number;
  data: PromoCodeValues;
}) =>
  APIv4.put(`/promocodes/${params.id}`, adaptPromoCode(params.data)).then(
    ({ data }) => Boolean(data)
  );

export const deletePromoCode = ({ id }: { id: number }) =>
  APIv4.delete(`/promocodes/${id}`).then(({ data }) => data);

export const blockPromoCode = (params: { id: number; isActive: boolean }) =>
  APIv4.patch(`/promocodes/${params.id}`, { isActive: params.isActive }).then(
    ({ data }) => data
  );

export const addPromoCodeValue = (params: {
  id: number;
  data: PromoCodeValue;
}) =>
  APIv4.post(`/promocodes/${params.id}/values`, params.data).then(({ data }) =>
    Boolean(data)
  );

export const updatePromoCodeValues = (params: {
  id: number;
  data: {
    values: { id: number; startDate: string | null }[];
  };
}) =>
  APIv4.patch(`/promocodes/${params.id}/values`, params.data).then(({ data }) =>
    Boolean(data)
  );

export const deletePromoCodeValue = (params: { id: number; valueId: number }) =>
  APIv4.delete(`/promocodes/${params.id}/values/${params.valueId}`).then(
    ({ data }) => data
  );
