import React, { useContext } from 'react';
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SidebarContext } from '../contexts/SidebarContext';
import { Link, useLocation } from 'react-router-dom';
import { AllowedRoute, isSelected } from './helpers';

interface SidebarItemProps {
  item: AllowedRoute;
  isChild?: boolean;
}

const SidebarItem = ({ item, isChild }: SidebarItemProps) => {
  const location = useLocation();
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const routeClickHandle = () => {
    if (!isSmallScreen || !isSidebarOpen) {
      return;
    }
    toggleSidebar();
  };

  return (
    <ListItem disablePadding data-testid={`item-${item.path}`}>
      {item.path && (
        <ListItemButton
          component={Link}
          to={item.path}
          selected={isSelected(location.pathname, item.path)}
          sx={{
            height: isChild ? 36 : 48,
            px: 2.5,
            color: theme.palette.secondary.main,
            '&.Mui-selected': {
              backgroundColor: isChild
                ? theme.palette.primary.main
                : theme.palette.primary.extraLight,
              '&:not(:hover)': {
                color: isChild
                  ? theme.palette.primary.contrastText
                  : theme.palette.secondary.main,
              },
            },
            '&:hover': {
              backgroundColor: theme.palette.primary.extraLight,
            },
          }}
          onClick={routeClickHandle}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isSidebarOpen ? 4 : 0,
              justifyContent: 'center',
              color: theme.palette.secondary.light,
              '.Mui-selected &': {
                color: theme.palette.primary.main,
              },
            }}
          >
            {item.icon || <Box sx={{ width: '1.5rem', height: '1.5rem' }} />}
          </ListItemIcon>
          {isSidebarOpen && (
            <ListItemText
              primaryTypographyProps={{
                whiteSpace: 'nowrap',
                letterSpacing: 0.15,
                color: 'currentcolor',
                fontSize: isChild ? 14 : 16,
              }}
              primary={item.label}
            />
          )}
        </ListItemButton>
      )}
    </ListItem>
  );
};

export default SidebarItem;
