import axios, { AxiosInstance } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { AuthCookie } from '../components/_shared/types';
import cookies from 'src/libs/cookies';
import * as Sentry from '@sentry/react';

export const downloadLink = (url: string, directory: string) => {
  if (!url) return '';
  const basePath = process.env.REACT_APP_BASE_PATH;

  return url.includes('http')
    ? url
    : `${basePath}images/${directory.toLocaleLowerCase()}/${url}`;
};

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  headers: {
    post: {
      'Access-Control-Allow-Origin': '*',
    },
  },
});

API.interceptors.request.use(
  (config) => {
    const auth: AuthCookie | undefined = cookies.get('auth');
    if (auth) config.headers['Authorization'] = `Bearer ${auth.accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status !== 401 && error.response.status !== 404) {
        Sentry.captureException(error);
      }
      if (!error.response) Sentry.captureException(error);
      return Promise.reject(error);
    }
  }
);

const refreshAuthLogic = (API: AxiosInstance) => (failedRequest: any) => {
  const auth: AuthCookie | undefined = cookies.get('auth');

  if (!auth?.refreshToken && !auth?.userName) {
    if (failedRequest.response?.status === 401) {
      cookies.remove('auth', { path: '/' });
      // window.location.href = '/';
    }
    return Promise.reject(failedRequest);
  }

  return API.post('/auth/refresh', {
    userName: auth.userName,
    token: auth.refreshToken,
  })
    .then(({ data }) => {
      console.log('refresh');
      const now = new Date();
      const oneMonth = 30 * 24 * 60 * 60 * 1000;
      now.setTime(now.getTime() + oneMonth);
      cookies.set(
        'auth',
        {
          ...auth,
          accessToken: data.token,
          refreshToken: data.refreshToken,
        },
        { path: '/', expires: now, sameSite: 'lax' }
      );
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        cookies.remove('auth', { path: '/' });
        // window.location.href = '/';
      }
      Promise.reject(failedRequest);
    });
};

createAuthRefreshInterceptor(API, refreshAuthLogic(API), {
  pauseInstanceWhileRefreshing: true,
});

export default API;

// TODO APIv4
// Должно быть удалено после перехода на v4

const APIv4 = axios.create({
  baseURL: process.env.REACT_APP_API_URL?.replace('v2', 'v4'),
  responseType: 'json',
  headers: {
    post: {
      'Access-Control-Allow-Origin': '*',
    },
  },
});

APIv4.interceptors.request.use(
  (config) => {
    const auth: AuthCookie | undefined = cookies.get('auth');
    if (auth) config.headers['Authorization'] = `Bearer ${auth.accessToken}`;

    // Заменяем, так как API_URL в .env не подходит
    if (config.url?.includes('audit')) {
      config.baseURL = config.baseURL?.replace('admin', '');
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

APIv4.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status !== 401 && error.response.status !== 404) {
        Sentry.captureException(error);
      }
      if (!error.response) Sentry.captureException(error);
      return Promise.reject(error);
    }
  }
);

createAuthRefreshInterceptor(APIv4, refreshAuthLogic(APIv4), {
  pauseInstanceWhileRefreshing: true,
  statusCodes: [401, 403],
});

export { APIv4 };
