import React from 'react';
import { styled } from '@mui/material/styles';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Stack, StackProps, Typography } from '@mui/material';

type InfoBlockProps = {
  reverse?: boolean;
  text?: string;
  tooltip?: string;
};

export const InfoBlock = (props: InfoBlockProps & StackProps) => {
  const { reverse, text, tooltip, ...rest } = props;

  return (
    <Stack {...rest} {...(reverse && { direction: 'column-reverse' })}>
      {tooltip && (
        <Typography
          variant="helperText"
          component="span"
          color="inherit"
          textTransform="none"
          noWrap
        >
          {tooltip}
        </Typography>
      )}
      {text && (
        <Typography
          variant="body2"
          component="span"
          color="inherit"
          textTransform="none"
          letterSpacing="initial"
          sx={{ wordBreak: 'break-word' }}
        >
          {text}
        </Typography>
      )}
    </Stack>
  );
};

type TouchButtonProps = LoadingButtonProps & InfoBlockProps;

const TouchButton = (props: TouchButtonProps) => {
  const { reverse, text, tooltip, children, color, variant, ...rest } = props;

  return (
    <LoadingButton
      disableElevation
      disableRipple
      disableFocusRipple
      color={color || 'primary'}
      variant={variant || 'contained'}
      {...rest}
    >
      {children || (
        <InfoBlock reverse={reverse} text={text} tooltip={tooltip} />
      )}
    </LoadingButton>
  );
};

export const StyledButton = styled(TouchButton)(({ theme }) => ({
  minWidth: '0rem',
  userSelect: 'none',
  minHeight: '3.125rem',
  paddingTop: 0,
  paddingBottom: 0,
  fontSize: '0.9375rem',
  lineHeight: '1.05rem',
  letterSpacing: '0.02875rem',
  borderRadius: 0,
  [`& > .MuiButton-startIcon`]: {
    marginLeft: 0,
  },
  [`& > .MuiButton-startIcon, & > .MuiButton-endIcon`]: {
    [`& > *:nth-of-type(1)`]: {
      fontSize: '2.1875rem',
    },
  },
  [`&.Mui-disabled`]: {
    [`&.MuiButton-contained`]: {
      color: theme.palette.light.main,
      backgroundColor: theme.palette.secondary.extraLight,
    },
  },
  [`&.MuiButton-containedSecondary`]: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.light,
  },
  [`&.MuiButton-containedSecondary:hover`]: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface StyledCommonButtonProps extends TouchButtonProps {
  isActive?: boolean;
  dark?: boolean;
}

export const StyledCommonButton = styled(TouchButton, {
  shouldForwardProp: (prop: string) => prop !== 'isActive' && prop !== 'dark',
})<StyledCommonButtonProps>(({ isActive, dark, theme }) => ({
  minWidth: '0rem',
  userSelect: 'none',
  minHeight: '3.125rem',
  padding: '8px 16px',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '1.05rem',
  letterSpacing: '0.17px',
  textTransform: 'none',
  wordBreak: 'break-word',
  borderRadius: 0,
  [`&:not(.Mui-disabled)`]: {
    color: `${
      isActive && dark ? 'white' : theme.palette.secondary.main
    }!important`,
    backgroundColor: `${
      isActive ? theme.palette.primary[dark ? 'main' : 'extraLight'] : 'white'
    }!important`,
  },
  borderColor: theme.palette.light.main,
  borderStyle: 'solid',
  [`&.Mui-disabled`]: {
    [`&.MuiButton-contained`]: {
      color: theme.palette.light.main,
      backgroundColor: theme.palette.secondary.extraLight,
    },
  },
}));
