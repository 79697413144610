import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  PhoneDisabled,
  PhoneEnabled,
} from '@mui/icons-material';

type ConnectionButtonProps = ButtonProps & {
  isConnected: boolean;
  isOpen: boolean;
};

export const ConnectionButton = (props: ConnectionButtonProps) => {
  const { isConnected, isOpen, children, ...rest } = props;

  return (
    <Button
      {...rest}
      disableElevation
      color={isConnected ? 'primary' : 'accent'}
      sx={{
        px: 2,
        gap: 1,
        ...(isConnected
          ? { color: 'secondary.main', backgroundColor: 'primary.extraLight' }
          : { backgroundColor: 'accent.extraLight' }),
      }}
    >
      {isConnected ? (
        <>
          <PhoneEnabled />
          Подключено
        </>
      ) : (
        <>
          <PhoneDisabled />
          Отключено
        </>
      )}
      {isOpen ? <ExpandLess /> : <ExpandMore />}
    </Button>
  );
};
