import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cookies from 'src/libs/cookies';
import { AuthCookie, UserCookie } from './types';
import * as signalR from '@microsoft/signalr';
import { OrderHistoryStatus } from 'src/pages/orders/shared/services/fetchOrders';
import { roleSet } from '../../constants/role';

interface MangoProviderProps {
  children: ReactNode;
}

export type CallCustomer = {
  id: number;
  name: string | null;
  phone: string;
  comment: string | null;
};

export type CallOrder = {
  receivingMethod: number;
  address: string;
  waitingTime: string | null;
  readyTime: string;
  id: number;
  status: OrderHistoryStatus;
};

type Call = {
  employeeId: number;
  customer: CallCustomer;
  orders: CallOrder[];
};

type MangoContextType = {
  isMangoAvailable: boolean;
  userId: number | null;
  isConnected: boolean;
  connect: () => void;
  disconnect: () => void;
  callData: Call | null;
  isCall: boolean;
};

export const MangoContext = createContext<MangoContextType>(
  {} as MangoContextType
);

const MangoMessage = {
  CALL_BEGIN: 'IncomingCallBegin',
  CALL_END: 'IncomingCallEnd',
};

export const MangoProvider = ({ children }: MangoProviderProps) => {
  const auth: AuthCookie | undefined = cookies.get('auth');
  const user: UserCookie | undefined = cookies.get('user');
  const token = auth?.accessToken || '';
  const userId = user?.id || null;
  const isMangoAvailable = Boolean(
    user?.role && roleSet.operatorToSuperAdmin.includes(user.role)
  );

  // Mango SignalR
  const callData = useRef<Call | null>(null);
  const [isCall, setIsCall] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [mangoConnectionSignalR, setMangoConnectionSignalR] =
    useState<signalR.HubConnection | null>(null);

  const start = useCallback(() => {
    const path = `${process.env.REACT_APP_BASE_PATH}mangoHub`;
    const connectionForMango = new signalR.HubConnectionBuilder()
      .withUrl(path, { accessTokenFactory: () => token })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Debug)
      .build();

    setMangoConnectionSignalR(connectionForMango);
  }, [token]);

  useEffect(() => {
    if (mangoConnectionSignalR) {
      mangoConnectionSignalR
        .start()
        .then(() => {
          setIsConnected(true);
          mangoConnectionSignalR.on(
            MangoMessage.CALL_BEGIN,
            (response: Call) => {
              if (response.employeeId !== userId) return;
              if (!callData.current) {
                callData.current = response;
                setIsCall(true);
              }
            }
          );
          mangoConnectionSignalR.on(
            MangoMessage.CALL_END,
            (response: number) => {
              if (response === userId) {
                callData.current = null;
                setIsCall(false);
              }
            }
          );
        })
        .catch((error) => {
          console.error('SignalR Mango connection failed: ', error.toString());
          callData.current = null;
          setIsConnected(false);
        });

      // mangoConnectionSignalR.onclose(start);
    }
  }, [mangoConnectionSignalR, start, userId]);

  const connect = useCallback(() => {
    if (!isConnected) start();
  }, [start, isConnected]);

  const disconnect = useCallback(() => {
    if (mangoConnectionSignalR) {
      mangoConnectionSignalR
        .stop()
        .then(() => setIsConnected(false))
        .catch((error) => {
          console.error('SignalR Mango connection failed: ', error.toString());
          setIsConnected(false);
        });
    }
    callData.current = null;
    setIsCall(false);
  }, [mangoConnectionSignalR]);

  return (
    <MangoContext.Provider
      value={{
        isMangoAvailable,
        userId,
        isConnected,
        connect,
        disconnect,
        callData: callData.current,
        isCall,
      }}
    >
      {children}
    </MangoContext.Provider>
  );
};
