import React, { FC, memo, useContext } from 'react';
import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router';

import { SidebarContext } from './contexts/SidebarContext';
import cookies from 'src/libs/cookies';
import { UserCookie } from 'src/components/_shared/types';
import { getUserName } from 'src/components/_shared/utils';
import { MangoStatus } from '../../pages/management/MangoPage/Mango';
import { roleSet } from '../../constants/role';
import CurrentTime from './CurrentTime';
import { toggleMangoEmployee } from '../../pages/management/MangoPage/services';
import { useQueryClient } from '@tanstack/react-query';
import { useAppSettings } from 'src/hooks/useAppSettings';

const useStyles = makeStyles((theme: any) => ({
  toolBar: {
    display: 'flex',
    paddingLeft: theme.spacing(2.5),
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  iconButton: {
    marginLeft: 'auto',
  },
}));

export const useUser = () => {
  const user: UserCookie | undefined = cookies.get('user');
  const userName = getUserName(user);

  return { user, userName };
};

const Header: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const client = useQueryClient();
  const { toggleSidebar } = useContext(SidebarContext);

  const { user, userName } = useUser();

  const { data } = useAppSettings();

  const logout = async () => {
    try {
      if (user?.role && roleSet.operatorToSuperAdmin.includes(user.role)) {
        await toggleMangoEmployee();
      }
      // нам нужно указывать path для того, чтобы cookies чистились из любого места (роута) системы
      cookies.remove('user', { path: '/' });
      cookies.remove('auth', { path: '/' });
      navigate('/auth');
    } catch (e) {
      console.log(e);
    } finally {
      client.clear();
    }
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolBar} color="white">
        <Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleSidebar}
            edge="start"
            size="large"
            sx={{ mr: 2 }}
            data-testid="toggle_nav_sidebar"
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            color="inherit"
            onClick={() => navigate('')}
          >
            {data?.title}: Админка
          </Typography>
        </Box>
        <Stack direction="row" gap={2}>
          <Box sx={{ mx: 2 }}>
            <Typography
              variant="h6"
              noWrap
              color="inherit"
              data-testid="user_city"
            >
              {user?.city.name}
            </Typography>
            <CurrentTime />
          </Box>
          <Typography
            variant="h6"
            noWrap
            color="inherit"
            data-testid="user_name"
          >
            {userName}
          </Typography>
          <MangoStatus />
          <IconButton
            color="inherit"
            className={classes.iconButton}
            onClick={logout}
            size="large"
            data-testid="logout_button"
          >
            <ExitToAppIcon />
          </IconButton>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default memo(Header);
