import React from 'react';
import { useRouteError, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Box } from '@mui/system';

const ErrorPage = () => {
  const navigate = useNavigate();
  const error = useRouteError();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* @ts-ignore */}
      {error?.status == '404' ? (
        <>
          <h1>404</h1>
          <h2>Cтраница не найдена</h2>
        </>
      ) : (
        <h1>ошибка</h1>
      )}

      <Button variant="contained" onClick={() => navigate(-1)}>
        Назад
      </Button>
      <Button variant="contained" onClick={() => navigate('/main')}>
        На главную
      </Button>
    </Box>
  );
};

export default ErrorPage;
