import React, {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
} from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import {
  DialogActions,
  DialogContent,
  DialogProps,
  DialogContentProps,
  DialogContentText,
  Box,
} from '@mui/material';
import {
  Confirm,
  CustomDialog,
  CustomDialogTitle,
  CustomDivider,
  Decline,
} from '.';
import { TouchButton } from 'src/uikit/TouchButton';
import { touchView } from 'src/constants/order';

type ModalProps = InstanceProps<any, any> & {
  data: {
    title?: React.ReactNode;
    description?: React.ReactNode;
    children?: React.ReactNode;
    confirmText?: string;
    rejectText?: string;
  };
  dialogProps?: Partial<DialogProps>;
  contentProps?: Partial<DialogContentProps>;
};

const Modal = (props: ModalProps) => {
  const { isOpen, onResolve, data, dialogProps, contentProps } = props;
  const { title, description, children, confirmText, rejectText } = data;

  const handleConfirm = () => onResolve(true);
  const handleReject = () => onResolve(false);
  const handleClose = () => onResolve();

  const childrenWithProps =
    typeof children === 'string'
      ? children
      : Children.map<ReactNode, ReactNode>(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(
              child as React.ReactElement<Pick<ModalProps, 'onResolve'>>,
              {
                onResolve,
              }
            );
          }
          return child;
        });

  return (
    <CustomDialog open={isOpen} onClose={handleClose} {...dialogProps}>
      {title && (
        <CustomDialogTitle onClose={handleClose}>{title}</CustomDialogTitle>
      )}
      <DialogContent {...contentProps}>
        {description && <DialogContentText>{description}</DialogContentText>}
        {childrenWithProps}
      </DialogContent>
      {(rejectText || confirmText) &&
        (touchView() ? (
          <Box display="flex">
            <TouchButton color="secondary" onClick={handleReject} fullWidth>
              {rejectText}
            </TouchButton>
            <TouchButton onClick={handleConfirm} fullWidth>
              {confirmText}
            </TouchButton>
          </Box>
        ) : (
          <>
            <CustomDivider />
            <DialogActions>
              <Decline onClick={handleReject}>{rejectText}</Decline>
              <Confirm onClick={handleConfirm}>{confirmText}</Confirm>
            </DialogActions>
          </>
        ))}
    </CustomDialog>
  );
};

export const ModalDialog = create<ModalProps, any, any>(Modal);
