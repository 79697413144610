import React, {
  FC,
  useState,
  createContext,
  ReactNode,
  useEffect,
} from 'react';
import { DrawerSize } from '../Sidebar';

type SidebarContext = {
  isSidebarOpen: boolean;
  sidebarWidth: number;
  toggleSidebar: () => void;
  closeSidebar: () => void;
};

export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

interface SidebarProviderProps {
  children: ReactNode;
}

export const SidebarProvider: FC<SidebarProviderProps> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(DrawerSize.INACTIVE);
  const toggleSidebar = () => {
    setIsSidebarOpen((value) => !value);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  useEffect(() => {
    setSidebarWidth(isSidebarOpen ? DrawerSize.ACTIVE : DrawerSize.INACTIVE);
  }, [isSidebarOpen]);

  return (
    <SidebarContext.Provider
      value={{ isSidebarOpen, toggleSidebar, closeSidebar, sidebarWidth }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
