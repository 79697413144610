import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useState,
} from 'react';

export const INITIAL_PAGE = 0;
export const INITIAL_PER_PAGE = 25;

const initialFilters = {
  clientSearch: '',
  orderSearch: '',
  orderTypeSearch: null,
  orderType: null,
  page: INITIAL_PAGE,
  rowsPerPage: INITIAL_PER_PAGE,
  isExpanded: false,
  isWarning: false,
};

export type Filters = {
  clientSearch: string;
  orderSearch: string;
  orderTypeSearch: null | number;
  orderType: null | number;
  page: number;
  rowsPerPage: number;
  isExpanded: boolean;
  isWarning: boolean;
};

const initialStatuses = {
  total: 0,
  isWarningTotal: 0,
  statuses: [
    {
      statusCode: 0,
      count: 0,
      isWarningCount: 0,
    },
    {
      statusCode: 10,
      count: 0,
      isWarningCount: 0,
    },
    {
      statusCode: 20,
      count: 0,
      isWarningCount: 0,
    },
    {
      statusCode: 30,
      count: 0,
      isWarningCount: 0,
    },
    {
      statusCode: 40,
      count: 0,
      isWarningCount: 0,
    },
    {
      statusCode: 50,
      count: 0,
      isWarningCount: 0,
    },
    {
      statusCode: 60,
      count: 0,
      isWarningCount: 0,
    },
    {
      statusCode: 70,
      count: 0,
      isWarningCount: 0,
    },
    {
      statusCode: 80,
      count: 0,
      isWarningCount: 0,
    },
  ],
};

export type Status = {
  statusCode: number;
  count: number;
  isWarningCount: number;
};

export type Statuses = {
  total: number;
  isWarningTotal: number;
  statuses: Status[];
};

type OrdersContext = {
  isNeedToUpdate: boolean;
  updateOrders: () => void;
  cancelUpdateOrders: () => void;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  statuses: Statuses;
  setStatuses: React.Dispatch<React.SetStateAction<Statuses>>;
};

export const OrdersContext = createContext<OrdersContext>({} as OrdersContext);
export const useOrdersContext = () => useContext(OrdersContext);

interface OrdersProviderProps {
  children: ReactNode;
}

export const OrdersProvider: FC<OrdersProviderProps> = ({ children }) => {
  const [isNeedToUpdate, setIsNeedToUpdate] = useState(false);
  const updateOrders = () => setIsNeedToUpdate(true);
  const cancelUpdateOrders = () => setIsNeedToUpdate(false);

  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [statuses, setStatuses] = useState<Statuses>(initialStatuses);

  return (
    <OrdersContext.Provider
      value={{
        isNeedToUpdate,
        updateOrders,
        cancelUpdateOrders,
        filters,
        setFilters,
        statuses,
        setStatuses,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};
