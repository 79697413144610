import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Popover,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { CustomDivider } from '../../../../components/MainPage/_shared/Dialog';
import { MangoContext } from 'src/components/_shared/MangoContext';
import { NumberButton } from './NumberButton';
import { ConnectionButton } from './ConnectionButton';

import { useMangoActions, useMangoNumbers } from './hooks';

const MangoStatus = () => {
  const { isMangoAvailable, userId, isConnected, connect, disconnect } =
    useContext(MangoContext);

  const onToggle = (shouldToggle: boolean) =>
    shouldToggle ? connect() : disconnect();

  const { data: mangoNumbers, refetch } = useMangoNumbers();
  const { toggleMangoEmployee } = useMangoActions({ onToggle });
  const isUserConnected =
    isConnected && !!mangoNumbers?.some(({ user }) => userId === user?.id);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    refetch();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isUserConnected || !mangoNumbers || !userId) return;
    if (mangoNumbers?.find(({ user }) => user?.id === userId)) connect();
  }, [userId, isUserConnected, connect, mangoNumbers]);

  const handlePick = async (number: string) => {
    await toggleMangoEmployee.mutateAsync(undefined);
    await toggleMangoEmployee.mutateAsync({ number });
    handleClose();
  };

  const handleUnpick = async () => {
    await toggleMangoEmployee.mutateAsync(undefined);
    handleClose();
  };

  if (!isMangoAvailable) return <></>;

  return (
    <>
      <ConnectionButton
        isOpen={isOpen}
        isConnected={isUserConnected}
        disabled={userId === null}
        onClick={handleOpen}
      />
      <Popover
        id="mango-popover"
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ mt: 1 }}
      >
        <Stack direction="row" alignItems="center" p={2} gap={3}>
          <Typography variant="h6" color="secondary.light" mr="auto">
            Подключение к Манго
          </Typography>
          <Switch
            disabled={!isUserConnected}
            checked={isUserConnected}
            onChange={handleUnpick}
          />
        </Stack>
        <CustomDivider />
        <Stack py={1} px={0} m={0} component="ul">
          {Boolean(mangoNumbers?.length) &&
            mangoNumbers?.map(({ mangoId, user }) => {
              const isOwn = userId === user?.id;
              const isBusy = Boolean(!isOwn && user);

              return (
                <Tooltip
                  key={mangoId}
                  title={isBusy && user?.name}
                  placement="left"
                  arrow
                >
                  <Box component="li">
                    <NumberButton
                      isBusy={isBusy}
                      isOwn={isOwn}
                      onClick={() => handlePick(mangoId)}
                      disabled={isOwn || isBusy}
                    >
                      {mangoId}
                    </NumberButton>
                  </Box>
                </Tooltip>
              );
            })}
        </Stack>
      </Popover>
    </>
  );
};

export default MangoStatus;
