export function getErrorMsg(
  error: any | null,
  frontendDefinedMessage = 'Ошибка получения ответа от сервера'
) {
  const backendDefinedMessage =
    error?.data?.errors?.errors ||
    error?.data?.errors ||
    error?.response?.data?.errors ||
    error?.response?.data?.message ||
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.message;

  if (backendDefinedMessage) {
    if (typeof backendDefinedMessage === 'string') {
      return backendDefinedMessage;
    }

    return Array.isArray(backendDefinedMessage)
      ? backendDefinedMessage.join('. ')
      : frontendDefinedMessage;
  }

  return frontendDefinedMessage;
}
