import React from 'react';
import { Box, BoxProps, Paper } from '@mui/material';

export const PageContent = (props: BoxProps & { asPaper?: boolean }) => {
  const { children, asPaper, sx, ...rest } = props;

  return (
    <Box
      className="pageContent"
      {...(asPaper && { component: Paper })}
      sx={{
        ...sx,
        ...(asPaper && {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }),
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
