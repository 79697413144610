import React, { lazy } from 'react';
import PeopleIcon from '@mui/icons-material/People';
import ProtectedRoute from 'src/pages/utility/ProtectedRoute';
import { getCrumb } from 'src/components/MainPage/_shared/Breadcrumbs/Breadcrumb';
import { roleSet } from 'src/constants/role';
import type { AppRoute } from '../types';

const EmployeesPage = lazy(
  () => import('src/pages/users/EmployeesPage/EmployeesPage')
);
const EmployeePage = lazy(
  () => import('src/pages/users/EmployeesPage/EmployeeForm/EmployeeForm')
);
const CustomersPage = lazy(
  () => import('src/pages/users/CustomersPage/CustomersPage')
);
const CustomerPage = lazy(
  () => import('src/pages/users/CustomersPage/CustomerForm/CustomerForm')
);

export const usersRoutes: AppRoute[] = [
  {
    element: <ProtectedRoute roles={roleSet.adminToSuperAdmin} />,
    handle: {
      label: 'Пользователи',
      icon: <PeopleIcon />,
      roles: roleSet.adminToSuperAdmin,
    },
    children: [
      {
        path: `employees`,
        handle: {
          crumb: getCrumb('Сотрудники', true),
        },
        children: [
          {
            index: true,
            element: <EmployeesPage />,
            handle: {
              label: 'Сотрудники',
              roles: roleSet.adminToSuperAdmin,
            },
          },
          {
            path: `:id`,
            element: <EmployeePage />,
            handle: {
              label: 'Сотрудник',
              roles: roleSet.adminToSuperAdmin,
            },
          },
        ],
      },
      {
        path: `customers`,
        handle: {
          crumb: getCrumb('Клиенты', true),
        },
        children: [
          {
            index: true,
            element: <CustomersPage />,
            handle: {
              label: 'Клиенты',
              roles: roleSet.adminToSuperAdmin,
            },
          },
          {
            path: `:id`,
            element: <CustomerPage />,
            handle: {
              label: 'Клиент',
              roles: roleSet.adminToSuperAdmin,
            },
          },
        ],
      },
    ],
  },
];
