import type { AppRoute, AppRouteHandle } from 'src/router';

export type AllowedRoute = AppRouteHandle & {
  path: AppRoute['path'];
  children: AllowedRoute[] | undefined;
};

export const getAllowedRoutes = ({
  routes,
  role,
  basePath = '',
}: {
  routes: AppRoute[];
  role?: string;
  basePath?: string;
}): AllowedRoute[] => {
  return routes
    .filter((route) => {
      const handle: AppRouteHandle | undefined = route.element
        ? route.handle
        : route.children?.find((i) => i.index)?.handle;

      return (route.path || route.children) && checkRoles(handle?.roles, role);
    })
    .reduce((acc, route) => {
      const path = [basePath, route.path].join('/').replace('//', '/');

      // in case of layout route
      if (route.element && !route.handle && route.children) {
        const allowedRoutes = getAllowedRoutes({
          routes: route.children,
          role,
          basePath: path,
        });

        acc = acc.concat(allowedRoutes);
      } else {
        const allowedRoute = {
          path,
          ...(route.element
            ? route.handle
            : route.children?.find((i) => i.index)?.handle),
          ...(route.children && {
            children: getAllowedRoutes({
              routes: route.children,
              role,
              basePath: path,
            }),
          }),
        };

        acc.push(allowedRoute);
      }

      return acc;
    }, [] as AllowedRoute[]);
};

const checkRoles = (roles?: string[], role?: string) => {
  if (!roles) return true;
  if (!role) return false;
  return roles.includes(role);
};

export const isSelected = (pathname: string, path?: string) => {
  if (!path) return false;
  return pathname === path || pathname.startsWith(path + '/');
};
