import { APIv4 } from 'src/libs/axios';

export const swapCategoryProducts = ({
  categoryId,
  ids,
}: {
  categoryId: number;
  ids: [number, number];
}): Promise<void> =>
  APIv4.put(`/categories/${categoryId}/products`, { ids }).then(
    (response) => response.data
  );
