import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useForceBack = (path?: string) => {
  const location = useLocation();
  const navigate = useNavigate();

  const stateForceBack = !!location.state?.forceBack;
  const forceBackCrumb = !!location.state?.forceBackCrumb;

  const forceBack =
    (stateForceBack || forceBackCrumb) && location.key !== 'default';

  const goBack = useCallback(
    (forceBack?: boolean) => {
      forceBack || !path ? navigate(-1) : navigate(path);
    },
    [navigate, path]
  );

  return { forceBack, forceBackCrumb, goBack };
};
