import { toast } from 'react-toastify';
import { ToastOptions, TypeOptions } from 'react-toastify/dist/types';
import { getErrorMsg } from 'src/utils/getErrorMsg';

enum AlertType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

const commonAlertOptions = {
  closeButton: true,
  closeOnClick: false,
  position: toast.POSITION.BOTTOM_CENTER,
  autoClose: 5000,
};

export const alertError = (
  error: any | null,
  message: string,
  options?: ToastOptions
) => {
  const type: TypeOptions = AlertType.ERROR;

  toast(getErrorMsg(error, message), {
    className: `custom-toast ${type}`,
    ...commonAlertOptions,
    ...options,
    type,
  });
};

export const alertSuccess = (message: string, options?: ToastOptions) => {
  const type: TypeOptions = AlertType.SUCCESS;

  toast(message, {
    className: `custom-toast ${type}`,
    ...commonAlertOptions,
    ...options,
    type,
  });
};

export const alertWarning = (
  error: any | null,
  message: string,
  options?: ToastOptions
) => {
  const type: TypeOptions = AlertType.WARNING;

  toast(getErrorMsg(error, message), {
    className: `custom-toast ${type}`,
    ...commonAlertOptions,
    ...options,
    type,
  });
};
