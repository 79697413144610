import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { CallOrder } from 'src/components/_shared/MangoContext';

type RightHeaderProps = {
  orders: CallOrder[];
  onNewOrderOpen: () => void;
};

const RightHeader = (props: RightHeaderProps) => {
  const { orders, onNewOrderOpen } = props;

  return (
    <Stack direction="row" alignItems="center" gap={1} py={1}>
      {Boolean(orders.length) && (
        <Typography variant="h6" color="secondary.lighter" pl={1.5}>
          Активные заказы: {orders.length}
        </Typography>
      )}
      <Button
        sx={{ ml: 'auto' }}
        size="small"
        variant="outlined"
        onClick={onNewOrderOpen}
      >
        Создать новый заказ
      </Button>
    </Stack>
  );
};

export default RightHeader;
