import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { mainRoutes } from './main';
import { roleSet } from 'src/constants/role';

import MainPage from 'src/components/MainPage';
import AuthPage from 'src/pages/utility/AuthPage';
import ErrorPage from 'src/pages/utility/ErrorPage';
import NoMatchPage from 'src/pages/utility/NoMatchPage';
import RedirectRoute from 'src/pages/utility/RedirectRoute';
import ProtectedRoute from 'src/pages/utility/ProtectedRoute';
import UnauthorizedPage from 'src/pages/utility/UnauthorizedPage';

export const router = createBrowserRouter([
  { path: '/', element: <RedirectRoute /> },
  {
    path: 'auth',
    element: <AuthPage />,
  },
  {
    path: 'unauthorized',
    element: <UnauthorizedPage />,
  },
  {
    path: '*',
    element: <NoMatchPage />,
  },
  {
    element: <ProtectedRoute roles={roleSet.all} />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'main',
        element: <MainPage />,
        children: [...mainRoutes],
      },
    ],
  },
]);
