import React, { memo, useContext, useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Container,
  Drawer,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { CallOrder, MangoContext } from 'src/components/_shared/MangoContext';
import { Link, useNavigate } from 'react-router-dom';
import {
  IconSize,
  OrderStatusIcon,
} from 'src/pages/orders/shared/components/OrderStatusIcon';

import { OrderType } from 'src/constants/order';
import { CustomDivider } from '../../../../components/MainPage/_shared/Dialog';
import { getDeliveryTime } from 'src/components/_shared/utils';
import LeftHeader from './LeftHeader';
import RightHeader from './RightHeader';

const MangoDrawer = () => {
  const navigate = useNavigate();
  const { callData, isCall } = useContext(MangoContext);
  const [isOpen, setIsOpen] = useState(isCall);

  useEffect(() => {
    setIsOpen(isCall);
  }, [isCall]);

  const hideDrawer = () => setIsOpen(false);

  const handleNewOrderOpen = () => {
    const newOrderPath = `/main/dispatcher/order-form/new?customerId=${callData?.customer?.id}`;
    hideDrawer();
    navigate(newOrderPath, { replace: true });
  };

  return (
    <>
      <Box
        hidden={!(isCall && !isOpen)}
        sx={{
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          zIndex: 1210,
          background: 'white',
          boxShadow: '0px -4px 8px -1px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Container sx={{ py: 1 }}>
          {callData && (
            <Grid container>
              <Grid item xs={12} md={6}>
                <LeftHeader
                  isOpen={isOpen}
                  customer={callData.customer}
                  onClick={() => setIsOpen((prev) => !prev)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RightHeader
                  onNewOrderOpen={handleNewOrderOpen}
                  orders={callData.orders}
                />
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
      <Drawer
        keepMounted
        anchor="bottom"
        open={isOpen}
        onClose={hideDrawer}
        sx={{ zIndex: 1210 }}
      >
        <Container sx={{ py: 5 }}>
          {callData && (
            <Grid container>
              <Grid item xs={12} md={6}>
                <LeftHeader
                  isOpen={isOpen}
                  customer={callData.customer}
                  onClick={() => setIsOpen((prev) => !prev)}
                />
                {isOpen && (
                  <Typography mt={2} ml={6} sx={{ mb: { xs: 2, md: 0 } }}>
                    {callData.customer.comment || 'Комментарии отсутствуют'}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <RightHeader
                  onNewOrderOpen={handleNewOrderOpen}
                  orders={callData.orders}
                />
                <Stack gap={1} sx={{ overflow: 'auto', maxHeight: 184 }}>
                  {callData.orders.map((order: CallOrder) => {
                    const {
                      id,
                      status,
                      address,
                      receivingMethod,
                      readyTime,
                      waitingTime,
                    } = order;

                    return (
                      <Link
                        key={id}
                        onClick={hideDrawer}
                        to={`/main/dispatcher/order-form/${id}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap={1}
                          py={1.5}
                          px={2}
                          sx={{
                            textDecoration: 'none',
                            '&:hover': { background: '#eee' },
                          }}
                        >
                          <Box display="flex" flexShrink={0}>
                            <OrderStatusIcon
                              status={status}
                              size={IconSize.SMALL}
                            />
                          </Box>
                          <Typography variant="body2">№ {id}</Typography>
                          <Typography variant="body2">
                            {getDeliveryTime(readyTime, waitingTime)}
                          </Typography>
                          <CustomDivider
                            orientation="vertical"
                            flexItem
                            sx={{ height: 16, alignSelf: 'center' }}
                          />
                          {receivingMethod ? (
                            <Typography variant="body2">{address}</Typography>
                          ) : (
                            <>
                              <Chip
                                label={OrderType.PICKUP.title}
                                size="small"
                                sx={{
                                  cursor: 'pointer',
                                  color: '#ff704c',
                                  background: '#f6e6e6',
                                  fontSize: 12,
                                  fontWeight: 500,
                                }}
                              />
                              <Chip
                                label={address}
                                size="small"
                                variant="outlined"
                                sx={{
                                  cursor: 'pointer',
                                  color: 'secondary.light',
                                  fontSize: 12,
                                  fontWeight: 500,
                                }}
                              />
                            </>
                          )}
                        </Stack>
                      </Link>
                    );
                  })}
                </Stack>
              </Grid>
            </Grid>
          )}
        </Container>
      </Drawer>
    </>
  );
};

export default memo(MangoDrawer);
