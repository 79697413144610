import React, { memo } from 'react';
import { useCurrentTime } from 'src/hooks/useCurrentTime';

const CurrentTime = () => {
  const { formattedTime } = useCurrentTime();

  return <span data-testid="current_time">{formattedTime}</span>;
};

export default memo(CurrentTime);
