import { LinearProgress, LinearProgressProps } from '@mui/material';
import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  progressBar: {
    position: 'absolute',
    bottom: '0px',
    width: '100%',
  },
}));

export const ProgressBar: FC<LinearProgressProps & { bottom?: boolean }> = ({
  bottom,
}) => {
  const classes = useStyles();
  return <LinearProgress className={bottom ? classes.progressBar : ''} />;
};
