import React, { lazy } from 'react';
import { roleSet } from 'src/constants/role';

const SyncEmployeesPage = lazy(() => import('./SyncEmployees'));
const SyncProductsPage = lazy(() => import('./SyncProducts'));
const SyncIngredientsPage = lazy(() => import('./SyncIngredients'));
const SyncSpecificsPage = lazy(() => import('./SyncSpecifics'));
const SyncPaymentsPage = lazy(() => import('./SyncPayments'));
const SyncShopsPage = lazy(() => import('./SyncShops'));

export const syncTabs = [
  {
    path: 'employees',
    element: <SyncEmployeesPage />,
    handle: { label: 'Сотрудники', roles: roleSet.adminToSuperAdmin },
  },
  {
    path: 'products',
    element: <SyncProductsPage />,
    handle: { label: 'Товары', roles: roleSet.adminToSuperAdmin },
  },
  {
    path: 'ingredients',
    element: <SyncIngredientsPage />,
    handle: { label: 'Ингредиенты', roles: roleSet.adminToSuperAdmin },
  },
  {
    path: 'specifics',
    element: <SyncSpecificsPage />,
    handle: { label: 'Специфики', roles: roleSet.adminToSuperAdmin },
  },
  {
    path: 'payments',
    element: <SyncPaymentsPage />,
    handle: {
      label: 'Типы оплаты',
      roles: roleSet.adminToSuperAdmin,
    },
  },
  {
    path: 'shops',
    element: <SyncShopsPage />,
    handle: { label: 'Точки продаж', roles: roleSet.adminToSuperAdmin },
  },
];
