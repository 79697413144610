import React, { memo } from 'react';
import { Params, useLocation, useNavigate } from 'react-router';
import { useMatches } from 'react-router-dom';
import { Breadcrumbs, Link } from '@mui/material';
import { Breadcrumb, BreadcrumbLabel } from './Breadcrumb';

import { useForceBack } from 'src/hooks/useForceBack';
import type { AppRouteHandle } from 'src/router';

type Match = {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle?: AppRouteHandle;
};

const RouterBreadcrumbs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { forceBack, forceBackCrumb } = useForceBack();
  const matches = useMatches() as Match[];

  const breadcrumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => {
      const { pathname: to, handle } = match;
      let label;

      if (typeof handle?.crumb === 'function') {
        label = handle.crumb(match.params);
      } else if (typeof handle?.crumb === 'string') {
        label = handle.crumb;
      }

      return { to, label, ...match };
    });

  if (forceBackCrumb) {
    return (
      <Link
        onClick={() => navigate(-1)}
        sx={{ cursor: 'pointer', textDecoration: 'none' }}
      >
        <Breadcrumb color="primary">
          <BreadcrumbLabel title="Назад" arrow />
        </Breadcrumb>
      </Link>
    );
  }

  return (
    <Breadcrumbs>
      {breadcrumbs.map(({ to, label }) => {
        const isCurrent = location.pathname === to;

        return isCurrent ? (
          <Breadcrumb key={to}>{label}</Breadcrumb>
        ) : (
          <Link
            key={to}
            onClick={() => (forceBack ? navigate(-1) : navigate(to))}
            sx={{ cursor: 'pointer', textDecoration: 'none' }}
          >
            <Breadcrumb color="primary">{label}</Breadcrumb>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default memo(RouterBreadcrumbs);
