import React, { FC, memo, Suspense } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { styled } from '@mui/material/styles';
import Header from './Header';
import Sidebar from './Sidebar';
import { SidebarProvider } from './contexts/SidebarContext';
import { OrdersProvider } from './contexts/OrdersContext';
import ContentWrapper from './ContentWrapper';
import { StatusProvider } from '../_shared/StatusContext';
import { MangoProvider } from '../_shared/MangoContext';
import { MangoDrawer } from '../../pages/management/MangoPage/Mango';
import { Outlet } from 'react-router';
import { Loader } from './_shared/page';

const AppRoot = styled(Box)(
  ({ theme }) => `
    height: 100%;
    display: flex;
  `
);

const MainPage: FC = () => {
  return (
    <SidebarProvider>
      <MangoProvider>
        <StatusProvider>
          <OrdersProvider>
            <AppRoot>
              <CssBaseline />
              <Header />
              <Sidebar />
              <ContentWrapper>
                <Suspense fallback={<Loader />}>
                  <Outlet />
                </Suspense>
              </ContentWrapper>
              <MangoDrawer />
            </AppRoot>
          </OrdersProvider>
        </StatusProvider>
      </MangoProvider>
    </SidebarProvider>
  );
};

export default memo(MainPage);
