import { APIv4 } from 'src/libs/axios';

export const swapMenuCategories = ({
  menuId,
  ids,
}: {
  menuId: number;
  ids: [number, number];
}): Promise<void> =>
  APIv4.put(`/menus/${menuId}/categories`, { ids }).then(
    (response) => response.data
  );
