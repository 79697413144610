import { APIv4 as API } from '../../../libs/axios';
import { alertError, alertSuccess } from 'src/libs/toastify';

const Messages = {
  FETCH_EMPLOYEES: 'Ошибка получения списка сотрудников',
  REFETCH_EMPLOYEES:
    'Обновление списка сотрудников в БД проекта данными из ЛК MangoOffice',
  EMPLOYEE_DISABLED: 'Сотрудник успешно отключён',
  EMPLOYEE_DISABLE_FAILED: 'Ошибка отключения сотрудника',
  MANGO: 'Ошибка подключения к Манго',
};

export type MangoNumber = {
  mangoId: string;
  name: string;
  user: {
    id: number;
    name: string;
  } | null;
};

export const fetchMangoNumbers = (): Promise<MangoNumber[]> =>
  API.get(`/mango/numbers`)
    .then(({ data }) => data)
    .catch((err) => alertError(err, Messages.FETCH_EMPLOYEES));

export const fetchMangoEmployees = (): Promise<number | void> =>
  API.put(`/mango/employees`)
    .then(({ status }) => {
      alertSuccess(`Успешно: ${Messages.REFETCH_EMPLOYEES}`);
      return status;
    })
    .catch((error) =>
      alertError(error, `Ошибка: ${Messages.REFETCH_EMPLOYEES}`)
    );

export const toggleMangoEmployee = (params?: { number: string }) =>
  API.patch(`/employees/mango`, null, { params })
    .then(({ data }) => data)
    .catch((error) => alertError(error, Messages.MANGO));

export const disableMangoEmployee = (userId: number): Promise<number | void> =>
  API.patch(`/employees/${userId}/mango/disable`)
    .then(({ status }) => {
      alertSuccess(Messages.EMPLOYEE_DISABLED);
      return status;
    })
    .catch((error) => alertError(error, Messages.EMPLOYEE_DISABLE_FAILED));
