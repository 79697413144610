import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import cookies from 'src/libs/cookies';
import { roleSet } from '../../constants/role';
import { AuthCookie, UserCookie } from './types';
import * as signalR from '@microsoft/signalr';
import { copyObject } from 'src/components/_shared/utils';
import { useLocation } from 'react-router';
import { useNewId } from 'src/hooks/isNewId';

function removeDuplicates<T>(arr: T[]): T[] {
  return arr.filter((item: T, index: number) => arr.indexOf(item) === index);
}

export type BlockedOrder = {
  isBlocked: boolean;
  orderId: number;
  userId: number;
};

interface employeeStatusI {
  online: string[];
  inactive: string[];
}

type StatusContext = {
  employeeStatus: employeeStatusI;
};

export const StatusContext = createContext<any>({});
export const useStatusContext = () => useContext(StatusContext);

interface StatusProviderProps {
  children: ReactNode;
}

export const StatusProvider: FC<StatusProviderProps> = ({ children }) => {
  const auth: AuthCookie | undefined = cookies.get('auth');
  const user: UserCookie | undefined = cookies.get('user');
  const token = auth?.accessToken || '';

  const { id, isNew } = useNewId();
  const location = useLocation();
  const orderId =
    location.pathname.includes('dispatcher/order-form') && !isNew
      ? id
      : undefined;

  // const [employeeStatus, setEmployeesStatus] = useState<employeeStatusI>({
  //   online: [],
  //   inactive: [],
  // });

  // const sendOnlineStatus = () => {
  //   if (user?.role && roleSet.operatorToSuperAdmin.includes(user.role)) {
  //     // API.post(`/signalRSend`, {
  //     //   userId: user?.id,
  //     //   userName: user?.firstName,
  //     //   status: 0,
  //     // });
  //   }
  // };

  // const [isNeedToSend, setIsNeedToSend] = useState(false);

  // useEffect(() => {
  //   if (isNeedToSend) {
  //     sendOnlineStatus();
  //     const id = setTimeout(() => {
  //       clearTimeout(id);
  //       setIsNeedToSend(false);
  //     }, 1000);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isNeedToSend, user]);

  // useEffect(() => {
  // if (user?.role && roleSet.operatorToSuperAdmin.includes(user.role)) {
  // const id = setInterval(() => {
  //   const myListener = function () {
  //     setIsNeedToSend(true);
  //     document.removeEventListener('mousemove', myListener, false);
  //   };
  //   document.addEventListener('mousemove', myListener, false);
  // }, 30000);
  // if (!isConnectedOrdersSignalR) {
  // const connection = new signalR.HubConnectionBuilder()
  //   .withUrl(`/statusHub`, {
  //     accessTokenFactory: () => token,
  //   })
  //   .configureLogging(signalR.LogLevel.Debug)
  //   .build();

  // connection
  //   .start()
  //   .then(() => console.log('SignalR connected'))
  //   .catch((error) => console.error('SignalR error', error.toString()));

  // connection.on('Receive', (response) => {
  //   if (response.status === 0) {
  //     setEmployeesStatus((employeeStatus) => {
  //       if (employeeStatus) {
  //         const newEmployeesStatus = copyObject(employeeStatus);
  //         newEmployeesStatus.online = removeDuplicates([
  //           ...newEmployeesStatus.online,
  //           response.userId,
  //         ]);
  //         newEmployeesStatus.inactive =
  //           newEmployeesStatus.inactive.filter(
  //             (el: string) => el !== response.userId
  //           );
  //         return newEmployeesStatus;
  //       } else {
  //         return employeeStatus;
  //       }
  //     });
  //   } else if (response.status === 2) {
  //     setEmployeesStatus((employeeStatus) => {
  //       if (employeeStatus) {
  //         const newEmployeesStatus = copyObject(employeeStatus);
  //         newEmployeesStatus.inactive = removeDuplicates([
  //           ...newEmployeesStatus.inactive,
  //           response.userId,
  //         ]);
  //         newEmployeesStatus.online = newEmployeesStatus.online.filter(
  //           (el: string) => el !== response.userId
  //         );
  //         return newEmployeesStatus;
  //       } else {
  //         return employeeStatus;
  //       }
  //     });
  //   } else {
  //     setEmployeesStatus((employeeStatus) => {
  //       if (employeeStatus) {
  //         const newEmployeesStatus = copyObject(employeeStatus);
  //         newEmployeesStatus.online = newEmployeesStatus.online.filter(
  //           (el: string) => el !== response.userId
  //         );
  //         newEmployeesStatus.inactive =
  //           newEmployeesStatus.inactive.filter(
  //             (el: string) => el !== response.userId
  //           );
  //         return newEmployeesStatus;
  //       } else {
  //         return employeeStatus;
  //       }
  //     });
  //   }
  // });
  // connection.on('IncomingCall', (response) => {
  //   console.log(response);
  // });

  // }
  // return () => clearInterval(id);
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [token, isConnectedOrdersSignalR]);

  // Orders SignalR
  const [isConnected, setIsConnected] = useState(false);
  const [ordersConnectionSignalR, setOrdersConnectionSignalR] =
    useState<signalR.HubConnection | null>(null);
  const [blockedOrders, setBlockedOrders] = useState<BlockedOrder[]>([]);

  const start = useCallback(() => {
    let url = `${process.env.REACT_APP_BASE_PATH}orderStatusHub`;
    if (orderId) url += `?orderId=${orderId}`;

    const connectionForOrders = new signalR.HubConnectionBuilder()
      .withUrl(url.toString(), {
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Debug)
      .build();

    setOrdersConnectionSignalR(connectionForOrders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    start();
  }, [start]);

  useEffect(() => {
    if (ordersConnectionSignalR) {
      ordersConnectionSignalR
        .start()
        .then(() => {
          setIsConnected(true);

          ordersConnectionSignalR.on('NewOrder', (response) => {
            console.log('NewOrder ' + response);
          });
          ordersConnectionSignalR.on('TryBlockOrder', (order: BlockedOrder) => {
            setBlockedOrders((prev) => [
              ...prev.filter((i) => i.orderId !== order.orderId),
              order,
            ]);
          });
          ordersConnectionSignalR.on(
            'TryUnblockOrder',
            (order: BlockedOrder) => {
              setBlockedOrders((prev) => [
                ...prev.filter((i) => i.orderId !== order.orderId),
              ]);
            }
          );
          ordersConnectionSignalR.on('ChangeOrderStatus', (response) => {
            console.log('ChangeOrderStatus ' + response);
          });

          // TODO: reconnect on error
          // ordersConnectionSignalR.hub.disconnected(function () {
          //   setTimeout(function () {
          //     ordersConnectionSignalR.start();
          //   }, 5000); // Restart connection after 5 seconds.
          // });
        })
        .catch((error) => {
          console.error('SignalR Orders connection failed: ', error.toString());
          setIsConnected(false);
        });

      ordersConnectionSignalR.onreconnecting((error) => {
        console.log('onreconnecting error:', error, ordersConnectionSignalR);
      });

      ordersConnectionSignalR.onreconnected((connectionId) => {
        console.log('onreconnected:', connectionId, ordersConnectionSignalR);
      });

      ordersConnectionSignalR.onclose(start);
    }
  }, [ordersConnectionSignalR, start]);

  const openOrder = useCallback(
    async (id: number) => {
      if (ordersConnectionSignalR) {
        try {
          const res = await ordersConnectionSignalR.invoke('TryBlockOrder', id);
          return {
            canOpen: !res.isBlocked || false,
            userId: res.userId || null,
          };
        } catch (e) {
          console.error((e as any).toString());
        }
      }
    },
    [ordersConnectionSignalR]
  );

  const closeOrder = useCallback(
    async (id: number) => {
      if (ordersConnectionSignalR) {
        try {
          await ordersConnectionSignalR.send('TryUnblockOrder', id);
        } catch (e) {
          console.error((e as any).toString());
        }
      }
    },
    [ordersConnectionSignalR]
  );

  const getBlockedOrders = useCallback(async () => {
    if (ordersConnectionSignalR) {
      try {
        const res = await ordersConnectionSignalR.invoke('GetBlockedOrders');
        setBlockedOrders(res || []);
      } catch (e) {
        console.error((e as any).toString());
      }
    }
  }, [ordersConnectionSignalR]);

  return (
    <StatusContext.Provider
      value={{
        blockedOrders,
        isConnected,
        handlers: { openOrder, closeOrder, getBlockedOrders },
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};
