import React from 'react';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { getFormattedPhoneNumber } from 'src/utils/phone';
import { CallCustomer } from 'src/components/_shared/MangoContext';

type LeftHeaderProps = {
  isOpen: boolean;
  customer: CallCustomer;
  onClick: () => void;
};

const LeftHeader = (props: LeftHeaderProps) => {
  const { isOpen, customer, onClick } = props;

  return (
    <Stack gap={1}>
      <Stack
        direction="row"
        alignItems="center"
        gap={1.5}
        py={1}
        onClick={onClick}
        sx={{ cursor: 'pointer' }}
      >
        <Stack alignItems="center" ml={1.5}>
          {isOpen ? <ExpandMore color="light" /> : <ExpandLess color="light" />}
        </Stack>
        <Typography variant="h6" color="primary">
          {getFormattedPhoneNumber(customer.phone)}
        </Typography>
        <Typography variant="h6" color="secondary">
          {customer.name}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default LeftHeader;
