import React, { useContext, useEffect, useState } from 'react';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { SidebarContext } from '../contexts/SidebarContext';
import SidebarItem from './SidebarItem';
import { useLocation } from 'react-router-dom';
import { AllowedRoute, isSelected } from './helpers';

type SidebarItemProps = {
  item: AllowedRoute;
};

const SidebarItemCollapse = ({ item }: SidebarItemProps) => {
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();

  const location = useLocation();
  const isSelectedChild = item.children?.some((child) =>
    isSelected(location.pathname, child.path)
  );

  const [open, setOpen] = useState(false);
  const collapseToggle = () => {
    if (!isSidebarOpen && !open) {
      toggleSidebar();
    }
    setOpen((value) => !value);
  };

  useEffect(() => {
    if (!isSidebarOpen) {
      setOpen(false);
    } else if (isSelectedChild) {
      setOpen(true);
    }
  }, [isSidebarOpen, isSelectedChild]);

  return (
    <>
      <ListItem disablePadding data-testid={`item-${item.label}`}>
        <ListItemButton
          sx={{
            height: 48,
            px: 2.5,
            color: theme.palette.secondary.main,
            '&.Mui-selected': {
              backgroundColor: theme.palette.primary.extraLight,
            },
            '&:hover': {
              backgroundColor: theme.palette.primary.extraLight,
            },
          }}
          selected={isSelectedChild}
          onClick={collapseToggle}
        >
          {item.icon && (
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isSidebarOpen ? 4 : 0,
                justifyContent: 'center',
                color: theme.palette.secondary.light,
                '.Mui-selected &': {
                  color: theme.palette.primary.main,
                },
              }}
            >
              {item.icon}
            </ListItemIcon>
          )}
          {isSidebarOpen && (
            <ListItemText
              primaryTypographyProps={{
                whiteSpace: 'nowrap',
                fontSize: 16,
                letterSpacing: 0.15,
                color: 'currentcolor',
              }}
              primary={item.label}
            />
          )}
          {open ? (
            <ExpandLess
              fontSize="small"
              sx={{
                position: isSidebarOpen ? 'static' : 'absolute',
                right: 2,
              }}
            />
          ) : (
            <ExpandMore
              fontSize="small"
              sx={{
                position: isSidebarOpen ? 'static' : 'absolute',
                right: 2,
              }}
            />
          )}
        </ListItemButton>
      </ListItem>
      {item.children?.length && (
        <Collapse in={open}>
          <List component="div" disablePadding>
            {item.children.map((route, index) => (
              <SidebarItem key={index} item={route} isChild={true} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default SidebarItemCollapse;
