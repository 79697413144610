import React from 'react';
import { Box, BoxProps, CircularProgress } from '@mui/material';

export const AppCircularProgress = () => (
  <CircularProgress variant="indeterminate" disableShrink />
);

export const Loader = (props: BoxProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%' }}
      {...props}
    >
      <AppCircularProgress />
    </Box>
  );
};
