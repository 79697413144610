import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchMangoNumbers, toggleMangoEmployee } from '../services';

export const useMangoNumbers = () =>
  useQuery({
    queryKey: ['mangoNumbers'],
    queryFn: fetchMangoNumbers,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

export const useMangoActions = ({
  onToggle,
}: {
  onToggle: (value: boolean) => void;
}) => {
  const client = useQueryClient();

  const toggleMutation = useMutation(toggleMangoEmployee, {
    onSuccess: (_, params) => {
      client.invalidateQueries(['mangoNumbers']);
      onToggle(Boolean(params?.number));
    },
  });

  return {
    toggleMangoEmployee: toggleMutation,
  };
};
