import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Drawer, List, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { SidebarContext } from '../contexts/SidebarContext';
import { MangoContext } from 'src/components/_shared/MangoContext';

import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';

import { getAllowedRoutes } from './helpers';
import { mainRoutes } from 'src/router/main';
import { useAuth } from 'src/hooks/useAuth';

export enum DrawerSize {
  INACTIVE = 64,
  ACTIVE = 256,
}

const Sidebar = () => {
  const { user } = useAuth();

  const { isCall } = useContext(MangoContext);
  const { isSidebarOpen, sidebarWidth, toggleSidebar } =
    useContext(SidebarContext);

  const [variant, setVariant] = useState<
    'permanent' | 'persistent' | 'temporary'
  >('permanent');

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isExtraSmallScreen) {
      setVariant('temporary');
      return;
    }
    if (isSmallScreen) {
      if (isSidebarOpen) {
        setVariant('temporary');
      } else {
        setVariant('permanent');
      }
      return;
    }
    setVariant('permanent');
  }, [isSidebarOpen, isSmallScreen, isExtraSmallScreen]);

  const allowedRoutes = useMemo(
    () =>
      getAllowedRoutes({
        routes: mainRoutes,
        role: user?.role,
        basePath: '/main',
      }),
    [user?.role]
  );

  return (
    <Drawer variant={variant} open={isSidebarOpen} onClose={toggleSidebar}>
      <Toolbar />
      <List
        component="nav"
        sx={{
          width: sidebarWidth,
          transition: 'width 0.2s',
          ...(isCall && { pb: { xs: '150px', md: '100px' } }),
        }}
      >
        {allowedRoutes.map((route, index) =>
          route.children?.length && !route.keepPlain ? (
            <SidebarItemCollapse key={index} item={route} />
          ) : (
            <SidebarItem key={index} item={route} />
          )
        )}
      </List>
    </Drawer>
  );
};

export default Sidebar;
