import { APIv4 } from 'src/libs/axios';

export type MenuCategory = {
  id: number;
  name: string;
  position: number;
  isActive: boolean;
};

export const fetchMenuCategories = (id: number): Promise<MenuCategory[]> =>
  APIv4.get(`/menus/${id}/categories`).then((response) => response.data);
