import React, { lazy } from 'react';

import { usersRoutes } from './usersRoutes';
import { cashRoutes } from './cashRoutes';
import { reportsRoutes } from './reportsRoutes';
import { ordersRoutes } from './ordersRoutes';
import { managementRoutes } from './managementRoutes';
import { catalogRoutes } from './catalogRoutes';
import { promotionsRoutes } from './promotionsRoutes';

const WelcomePage = lazy(() => import('src/pages/utility/WelcomePage'));

export const mainRoutes = [
  {
    index: true,
    element: <WelcomePage />,
  },
  ...ordersRoutes,
  ...cashRoutes,
  ...catalogRoutes,
  ...promotionsRoutes,
  ...usersRoutes,
  ...managementRoutes,
  ...reportsRoutes,
];
