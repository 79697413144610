import React from 'react';
import {
  Box,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { Breadcrumbs } from '../Breadcrumbs';

type PageHeaderProps = StackProps & {
  title?: string;
  titleProps?: TypographyProps;
  withBreadcrumbs?: boolean;
};

export const PageHeader = (props: PageHeaderProps) => {
  const { withBreadcrumbs, title, titleProps, children, sx, ...rest } = props;

  return (
    <Box>
      {withBreadcrumbs ? <Breadcrumbs /> : <></>}
      {(title || children) && (
        <Stack
          direction="row"
          justifyContent="space-between"
          gap={1}
          sx={{
            ...(!children && { mb: 0.5 }),
            mt: withBreadcrumbs ? 0 : 3,
            ...sx,
          }}
          {...rest}
        >
          {title && (
            <Typography variant="h5" {...titleProps}>
              {title}
            </Typography>
          )}
          {children}
        </Stack>
      )}
    </Box>
  );
};
