import React from 'react';
import { useNavigate } from 'react-router';
import { Button } from '@mui/material';
import { Box } from '@mui/system';

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1>Доступ запрещён</h1>
      <Button variant="contained" onClick={() => navigate(-1)}>
        Назад
      </Button>
      <Button variant="contained" onClick={() => navigate('/main')}>
        На главную
      </Button>
    </Box>
  );
};

export default UnauthorizedPage;
