import { useQuery } from '@tanstack/react-query';

const SETTINGS_URL = `/assets/settings/${process.env.REACT_APP_SETTINGS_FILE}`;

export const useAppSettings = () => {
  const settingsQuery = useQuery(
    ['app-settings'],
    async () => {
      const response = await fetch(SETTINGS_URL);
      return response.json();
    },
    { staleTime: Infinity, refetchOnWindowFocus: false }
  );

  return settingsQuery;
};
