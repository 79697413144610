import React, { lazy } from 'react';
import { Params } from 'react-router';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import WorkIcon from '@mui/icons-material/Work';
import ProtectedRoute from 'src/pages/utility/ProtectedRoute';
import {
  BreadcrumbLabel,
  getCrumb,
} from 'src/components/MainPage/_shared/Breadcrumbs/Breadcrumb';
import { roleSet } from 'src/constants/role';
import type { AppRoute } from '../types';

// v2 pages (old orders)
const ArchivePageOLD = lazy(() => import('src/pages/orders/ArchivePageOLD'));
const DispatcherOrderForm = lazy(
  () => import('src/components/MainPage/DispatcherPage/DispatcherOrderForm')
);
// v4 pages
const OrdersPage = lazy(() => import('src/pages/orders/OrdersPage'));
const OrderPage = lazy(() => import('src/pages/orders/OrderPage'));
const ArchivePage = lazy(() => import('src/pages/orders/ArchivePage'));

const orderHandle = {
  crumb: (params?: Params) => {
    const id = params?.id;
    const title = id === 'new' ? 'Новый заказ' : `Заказ № ${id}`;
    return <BreadcrumbLabel title={title} />;
  },
};

export const ordersRoutes: AppRoute[] = [
  {
    path: `v2/orders-archive`,
    element: <ProtectedRoute roles={roleSet.operatorToSuperAdmin} />,
    handle: {
      label: 'Архив заказов',
      icon: <WorkIcon color="warning" />,
      roles: roleSet.operatorToSuperAdmin,
      keepPlain: true,
      crumb: getCrumb('Архив заказов (v2)', true),
    },
    children: [
      {
        index: true,
        element: <ArchivePageOLD />,
      },
      {
        path: `order-form/:id`,
        element: <DispatcherOrderForm />,
      },
    ],
  },
  // Заказы диспетчера
  {
    path: `dispatcher`,
    element: <ProtectedRoute roles={roleSet.operatorToSuperAdmin} />,
    handle: {
      label: 'Заказы',
      icon: <SupportAgentIcon />,
      roles: roleSet.operatorToSuperAdmin,
      keepPlain: true,
      crumb: getCrumb('Диспетчерская', true),
    },
    children: [
      {
        index: true,
        element: <OrdersPage />,
      },
      {
        path: `order-form/:id`,
        element: <OrderPage />,
        handle: orderHandle,
      },
    ],
  },
  {
    path: `orders-archive`,
    element: <ProtectedRoute roles={roleSet.operatorToSuperAdmin} />,
    handle: {
      label: 'Архив заказов',
      icon: <WorkIcon />,
      roles: roleSet.operatorToSuperAdmin,
      keepPlain: true,
      crumb: getCrumb('Архив заказов', true),
    },
    children: [
      {
        index: true,
        element: <ArchivePage />,
      },
      {
        path: `order-form/:id`,
        element: <OrderPage />,
      },
    ],
  },
];
