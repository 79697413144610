import { QueryClient } from '@tanstack/react-query';
import { getProductSets } from 'src/components/MainPage/_shared/ProductSets/utils';
import {
  CommonViewProduct,
  fetchCommonViewProducts,
} from 'src/pages/catalog/MenuPage/services';
import {
  fetchPromoCode,
  fetchPromoCodeValues,
  GENERAL_TYPE,
} from 'src/pages/promotions/PromoCodesPage/services';

export const promocodeLoader =
  (queryClient: QueryClient) =>
  async ({ params }: any) => {
    let products: CommonViewProduct[] = [];

    try {
      products = (await fetchCommonViewProducts()).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    } catch {
      return null;
    }

    const id = Number(params.id);
    if (!products) return null;
    if (!id) return { data: null, products };

    try {
      const data = await fetchPromoCode({ id });
      if (!data) return null;

      // @ts-ignore
      data.conditions = getProductSets(products, data.conditions);

      // @ts-ignore
      data.presents = getProductSets(products, data.presents);

      if (data.type !== GENERAL_TYPE) {
        const query = 'pageNumber=1&pageSize=10&';
        await queryClient.fetchQuery(['promoCodesValues', id, query], () =>
          fetchPromoCodeValues({ id, query })
        );
      }

      return {
        data,
        products,
      };
    } catch {
      return null;
    }
  };
