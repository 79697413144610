import { APIv4 } from 'src/libs/axios';
import { OptionFull } from 'src/components/MainPage/_shared/Options';

export type MenuCategoryProductVariant = {
  isActive: boolean;
  price: number;
  receivingMethods: number[];
  id: number;
  options: OptionFull[];
};

export type MenuCategoryProduct = {
  isActive: boolean;
  variants: MenuCategoryProductVariant[];
  id: number;
  name: string;
  position: number;
};

export const fetchMenuCategoryProducts = (
  id: number
): Promise<MenuCategoryProduct[]> =>
  APIv4.get(`/categories/${id}/products-menu`).then(
    (response) => response.data
  );
